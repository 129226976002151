/*jshint esversion:6*/
import crypto from "crypto";

class Redsys {
  constructor(p) {
    Object.assign(this, p);
  }

  generateMerchantParams(payment) {
    return {
      DS_MERCHANT_AMOUNT: payment.total,
      DS_MERCHANT_ORDER: payment.order_id,
      DS_MERCHANT_MERCHANTCODE: this.merchantCode,
      DS_MERCHANT_CURRENCY: payment.currency,
      DS_MERCHANT_TRANSACTIONTYPE: this.transaction_type,
      DS_MERCHANT_TERMINAL: this.terminal,
      DS_MERCHANT_MERCHANTURL: payment.redirect_urls.merchant_url,
      DS_MERCHANT_URLOK: payment.redirect_urls.ok_url,
      DS_MERCHANT_URLKO: payment.redirect_urls.cancel_url,
      DS_MERCHANT_CONSUMERLANGUAGE: "001",
      DS_MERCHANT_TITULAR: this.titular,
      DS_MERCHANT_MERCHANTNAME: this.name,
      DS_MERCHANT_IDENTIFIER: this.setPayByReference,
      DS_MERCHANT_DIRECTPAYMENT: this.directPayment,
      DS_MERCHANT_PAYMETHODS: payment.payMethods,

      // Test code
      //DS_MERCHANT_PAN: "4548812049400004",
      //DS_MERCHANT_EXPIRYDATE: "1222",
      //DS_MERCHANT_CVV2: "123",
    };
  }

  encodeOrder(order_id, secret) {
    const secretKey = Buffer.from(secret, "base64");
    const iv = Buffer.alloc(8, 0);
    const cipher = crypto.createCipheriv("des-ede3-cbc", secretKey, iv);
    cipher.setAutoPadding(false);
    const buffer =
      typeof order_id === "string" ? Buffer.from(order_id, "utf8") : order_id;
    const pad = Buffer.alloc((8 - (buffer.length % 8)) % 8, 0);
    const zeroPad = Buffer.concat([buffer, pad]);
    const en_key =
      cipher.update(zeroPad, "utf8", "binary") + cipher.final("binary");
    const l = Math.ceil(order_id.length / 8) * 8;
    const result = Buffer.from(en_key.substr(0, l), "binary").toString(
      "base64"
    );
    return result;
  }

  zeroPad(buf, blocksize) {
    const buffer = typeof buf === "string" ? Buffer.from(buf, "utf8") : buf;
    const pad = Buffer.alloc(
      (blocksize - (buffer.length % blocksize)) % blocksize,
      0
    );
    return Buffer.concat([buffer, pad]);
  }

  doSignature(order_encoded, merchantData) {
    var hexMac256 = crypto
      .createHmac("sha256", new Buffer(order_encoded, "base64"))
      .update(merchantData)
      .digest("hex");
    return new Buffer(hexMac256, "hex").toString("base64");
  }

  _decodeNotifiedMerchantParams(signature, merchantData) {
    return new Promise((resolve, reject) => {
      let decodedData = JSON.parse(new Buffer(merchantData, "base64"));
      let key = this.encodeOrder(decodedData.Ds_Order, this.secret);
      let hexMac256 = crypto
        .createHmac("sha256", new Buffer(key, "base64"))
        .update(merchantData)
        .digest();
      let signatureBuffer = new Buffer(signature, "base64");
      if (hexMac256.equals(signatureBuffer)) {
        resolve(decodedData);
      } else {
        reject(new Error("Signature is not valid"));
      }
    });
  }

  getFormData(payment) {
    var merchantPayment = this.generateMerchantParams(payment);
    var merchant = new Buffer(JSON.stringify(merchantPayment)).toString(
      "base64"
    );

    var order_encoded = this.encodeOrder(payment.order_id, this.secret);
    var signature = this.doSignature(order_encoded, merchant);

    return {
      redsys_url: this.url,
      Ds_SignatureVersion: "HMAC_SHA256_V1",
      Ds_MerchantParameters: merchant,
      Ds_Signature: signature,
    };
  }
}

export default class RedsysBuilder {
  constructor() {
    this.name = "Default-Redsys";
    this.terminal = "001";
    this.language = "auto";
    this.transaction_type = "0";
    this.setPayByReference = "";
    this.directPayment = false;
    // Production URL
    if (process.env.NODE_ENV === "development") {
      this.url = "https://sis-t.redsys.es:25443/sis/realizarPago";
    } else {
      this.url = "https://sis.redsys.es/sis/realizarPago";
    }
  }
  setMerchantCode(merchant_code) {
    this.merchantCode = merchant_code;
    return this;
  }
  setTerminal(terminal_number) {
    this.terminal = terminal_number;
    return this;
  }
  setName(name) {
    this.name = name;
    return this;
  }
  setTitular(titular) {
    this.titular = titular;
    return this;
  }
  enablePayByReference(reference) {
    this.setPayByReference = reference || "REQUIRED";
    return this;
  }
  enableDirectPayment() {
    this.directPayment = true;
    return this;
  }
  setSecret(secret) {
    this.secret = secret;
    return this;
  }
  enableDebug() {
    // Change to debug url
    this.url = "https://sis-t.redsys.es:25443/sis/realizarPago";
    return this;
  }
  build() {
    if (this.merchantCode === undefined)
      throw new Error("Merchant Code not set");
    if (this.secret === undefined) throw new Error("Secret not set");
    return new Redsys(this);
  }
}
