import * as actionTypes from "../constants/cartConstants";

const CART_INITIAL_STATE = {
  cartItems: [],
};

const composeIdWithSupplements = (cartItem) => {
  if (cartItem.supplements !== undefined) {
    const supplementIds = cartItem.supplements
      .sort((firstItem, secondItem) =>
        firstItem.name > secondItem.name ? 1 : -1
      )
      .map((supplement) => supplement._id)
      .join("");

    return cartItem.id + supplementIds;
  }
  return cartItem.id;
};

const isEqual = (key, supplement1, supplement2) => {
  if (key === "tags") {
    return true;
  }
  return supplement1 === supplement2;
};

const filterCartItems = (supplements, cartItems) => {
  const filteredCartItems = cartItems.filter(
    (cartItem) =>
      cartItem.supplements !== undefined &&
      supplements !== undefined &&
      cartItem.supplements.length === supplements.length &&
      cartItem.supplements.every(
        (o, i) =>
          Object.keys(o).length === Object.keys(supplements[i]).length &&
          Object.keys(o).every((k) => isEqual(k, o[k], supplements[i][k]))
      )
  );

  return filteredCartItems;
};

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  const item = action.payload;
  let currentCartItem;
  let id;

  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      id = item._id !== undefined ? item._id : item.id;
      const filteredCartItems = filterCartItems(
        item.supplements,
        state.cartItems
      );
      const addCartItem = filteredCartItems.find(
        (cartItem) => cartItem.id === id
      );

      if (addCartItem) {
        addCartItem.qty = addCartItem.qty + 1;

        return {
          ...state,
          cartItems: state.cartItems.map((cartItem) =>
            composeIdWithSupplements(cartItem) ===
            composeIdWithSupplements(addCartItem)
              ? addCartItem
              : cartItem
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case actionTypes.REMOVE_FROM_CART:
      id = item._id !== undefined ? item._id : item.id;

      currentCartItem = state.cartItems.find(
        (cartItem) =>
          composeIdWithSupplements(cartItem) === composeIdWithSupplements(item)
      );

      if (currentCartItem !== undefined && currentCartItem.qty > 1) {
        currentCartItem.qty = currentCartItem.qty - 1;
        return {
          ...state,
          cartItems: state.cartItems.map((cartItem) =>
            composeIdWithSupplements(cartItem) ===
            composeIdWithSupplements(currentCartItem)
              ? currentCartItem
              : cartItem
          ),
        };
      } else {
        return {
          ...state,
          cartItems: state.cartItems.filter(
            (cartItem) =>
              composeIdWithSupplements(cartItem) !==
              composeIdWithSupplements(item)
          ),
        };
      }

    case actionTypes.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
