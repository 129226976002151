import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import bizum_icon from "../images/bizum.png";
import card_icon from "../images/targeta.png";
import "./Checkout.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { clearCart } from "../redux/actions/cartActions";
import { clearStore } from "../redux/actions/storeActions";
import SimpleDialog from "../components/SimpleDialog";

const Checkout = (data) => {
  const [discountAmount, setDiscountAmount] = useState(0);
  const [user, setUser] = useState();
  const [points, setPoints] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const selectedStore = useSelector((state) => state.selectStore);
  const { store } = selectedStore;
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedTip, setSelectedTip] = useState(0.0);
  const [tipChoice, setTipChoice] = useState();
  const selectedTimeTable = data.location.timetable;
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    if (
      store === undefined ||
      store.code === undefined ||
      store.service === undefined ||
      store.service.type === undefined ||
      (selectedTimeTable === undefined && store.service.type !== "restaurant")
    ) {
      history.push("/");
    }

    if (localStorage.getItem("authToken")) {
      setUser(jwt.decode(localStorage.getItem("authToken")));
      const userId = jwt.decode(localStorage.getItem("authToken")).id;
      initializeUserPoints(userId);
    }
  }, [store, selectedTimeTable, history]);

  const initializeUserPoints = async (userId) => {
    const { data } = await axios.get(`/api/users/${userId}`);
    if (data !== undefined) {
      setPoints(data !== undefined ? data.points : 0);
    }
  };

  const cartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const cartTotal = () => {
    return cartItems
      .reduce((price, item) => price + item.price * item.qty, 0)
      .toFixed(2);
  };

  const addDiscount = async () => {
    const { data } = await axios.get(`/api/users/${user.id}`);

    if (data !== undefined && data.points >= 100 && discountAmount === 0) {
      setDiscountAmount(1);
      setPoints(points - 100);
    }
  };

  const cost = () => {
    if (
      store !== undefined &&
      store.code !== undefined &&
      store.service !== undefined &&
      store.service.type !== "restaurant"
    ) {
      return (0.6).toFixed(2);
    }
    return (0.0).toFixed(2);
  };

  const hiddenCost = () => {
    if (
      store !== undefined &&
      store.code !== undefined &&
      store.service !== undefined &&
      store.service.type !== "restaurant"
    ) {
      return (0.0).toFixed(2);
    }

    return (cartCount() * 0.1).toFixed(2);
  };

  const discount = () => {
    return discountAmount.toFixed(2);
  };

  const tip = (option) => {
    if (option === "0") {
      return (0.0).toFixed(2);
    } else if (option === "1") {
      return (cartTotal() * 0.02).toFixed(2);
    } else {
      return (cartTotal() * 0.06).toFixed(2);
    }
  };

  const totalOrder = () => {
    return (
      Number(cartTotal()) +
      Number(currentTip()) +
      Number(cost()) -
      Number(discount())
    ).toFixed(2);
  };

  const currentTip = () => {
    return Number(selectedTip).toFixed(2);
  };

  const handleTipChange = (event) => {
    setTipChoice(tip(event.target.value));
    setSelectedTip(tip(event.target.value));
  };

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  var lines = [];
  var lineCount = 1;

  cartItems.map((item) => {
    const description =
      item.supplements !== undefined && item.supplements.length > 0
        ? item.supplements
            .sort((firstItem, secondItem) =>
              Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
            )
            .map((supplement) => supplement.name)
            .join(", ")
        : " ";

    lines.push({
      id: lineCount++,
      itemId: item.id,
      itemTitle: item.name,
      itemDescription:
        item.supplements !== undefined && item.supplements.length > 0
          ? item.supplements
              .sort((firstItem, secondItem) =>
                Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
              )
              .map((supplement) => supplement.name)
              .join(", ")
          : " ",
      itemCustomName: item.burgerName,
      price: item.price,
      quantity: item.qty,
    });

    return description;
  });

  const preOrder = async () => {
    const order = {
      store: store,
      table: null,
      tip: currentTip(),
      cost: cost(),
      hiddenCost: hiddenCost(),
      discount: discount(),
      totalAmount: totalOrder(),
      customer: user !== undefined ? user.email : "",
      phone: user !== undefined ? user.phone : "",
      lines: lines,
      paymentType: paymentMethod,
      timetable: selectedTimeTable,
      points: Math.trunc(totalOrder()),
    };

    if (discount() > 0) {
      await axios.put(`/api/users/${user.id}`, {
        email: user.email,
        points: -100,
        inc: true,
      });
    }
    const response = await axios.post("/api/orders", {
      order: order,
    });

    if (paymentMethod !== undefined && paymentMethod === "Barra") {
      history.push("/orders");
    } else {
      history.push({
        pathname: "redsys",
        orderNumber: response.data,
        totalAmount: totalOrder(),
        paymentMethod: paymentMethod,
      });
    }

    dispatch(clearCart());
    dispatch(clearStore());
  };

  const bizumHelp = (props) => (
    <Tooltip {...props}>
      Si teniu problemes amb aquest mètode de pagament, podeu obtenir més
      informació a la web de Bizum: https://bizum.es/pagar-compra-online/
    </Tooltip>
  );

  const onSubmitDialog = (button) => {
    if (button === "CANVIAR") {
      dispatch(clearStore());
      history.push("/");
    } else if (button === "ACCEPTAR") {
      preOrder();
    }
    setConfirmDialog(false);
  };

  return (
    <div>
      <div className="card">
        <div>
          <h6 className="card-header">RESUM</h6>
          <div className="checkoutWrapper">
            <div>
              <div className="card-header checkoutSubtotalAlign">
                Subtotal: {cartTotal()}€
                <br />
                {cost() > 0
                  ? "Cost gestió: " + cost() + "€"
                  : "Cost gestió incl."}
                <br />
                Propina: {currentTip()}€
                {(points !== undefined && points >= 100) ||
                discountAmount > 0 ? (
                  <div>Descompte: {discount()}€</div>
                ) : (
                  <br />
                )}
                TOTAL: {totalOrder()}€
              </div>
            </div>

            <div className="card-header">
              {points !== undefined ? (
                <div>
                  <p>Punts actuals: {points}</p>
                </div>
              ) : (
                <></>
              )}
              {points !== undefined && points >= 100 ? (
                discountAmount === 0 ? (
                  <div>
                    <Button variant="primary" size="sm" onClick={addDiscount}>
                      APLICAR DESCOMPTE
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button disabled variant="primary" size="sm">
                      APLICAR DESCOMPTE
                    </Button>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>

          <h6 className="card-header">PROPINA</h6>
          <p />
          <label>
            <input
              type="radio"
              name="tip"
              value="0"
              className="radio"
              onChange={handleTipChange}
            />
            {tip("0")} €
          </label>

          <p />
          <label>
            <input
              type="radio"
              name="tip"
              value="1"
              className="radio"
              onChange={handleTipChange}
            />
            {tip("1")} €
          </label>

          <p />
          <label>
            <input
              type="radio"
              name="tip"
              value="2"
              className="radio"
              onChange={handleTipChange}
            />
            {tip("2")} €
          </label>
          <p />
        </div>
        <div>
          <h6 className="card-header">MÈTODE DE PAGAMENT</h6>
          <p />
          <label>
            <input
              type="radio"
              name="method"
              value="Targeta"
              className="radio"
              onChange={handlePaymentChange}
            />
            <img src={card_icon} alt="Targeta" />
          </label>
          <label>
            <input
              type="radio"
              name="method"
              value="Bizum"
              className="radio"
              onChange={handlePaymentChange}
            />
            <img src={bizum_icon} alt="Bizum" />
            <OverlayTrigger placement="top" overlay={bizumHelp}>
              <a>
                <BsInfoCircle />
              </a>
            </OverlayTrigger>
          </label>

          {user !== undefined && user.role === "manager" ? (
            <label>
              <input
                type="radio"
                name="method"
                value="Barra"
                className="radio"
                onChange={handlePaymentChange}
              />
              BARRA
            </label>
          ) : (
            ""
          )}
          <p />
        </div>
      </div>
      <div>
        {cartCount() &&
        cartTotal() > 0 &&
        paymentMethod.length > 0 &&
        tipChoice !== undefined ? (
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              onClick={() => setConfirmDialog(true)}
            >
              PAGAR
            </Button>
          </div>
        ) : (
          <div className="d-grid gap-2">
            <Button disabled style={{ pointerEvents: "none" }} size="lg">
              PAGAR
            </Button>
          </div>
        )}
      </div>
      {confirmDialog && (
        <SimpleDialog
          key="test"
          onSubmitDialog={onSubmitDialog}
          text={`Estàs a punt de demanar per ${store.service.description} de ${
            store.name
          }. ${
            store.service.type === "take away"
              ? `\n (` + store.location + `)`
              : ""
          }
           \n Confirmes que és correcte?`
            .split("\n")
            .map((str) => (
              <p>{str}</p>
            ))}
          buttons={["ACCEPTAR", "CANVIAR", "CANCEL·LAR"]}
        />
      )}
    </div>
  );
};

export default Checkout;
