import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosHome } from "react-icons/io";
import {
  IoCartOutline,
  IoPersonOutline,
  IoFastFoodOutline,
} from "react-icons/io5";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import beive_logo from "../images/beive_logo.png";
import "./Navbar.css";

const Navbar = ({ cart }) => {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let count = 0;
    cart.forEach((item) => {
      count += item.qty;
    });

    setCartCount(count);
  }, [cart, cartCount]);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar_style d-print-none">
          <div className="menu-bars logo">
            <img src={beive_logo} alt="" />
          </div>
          <div className="menu-bars">
            <Link to="/">
              <IoIosHome />
            </Link>
          </div>
          <div className="menu-bars">
            <Link to="/menu">
              <IoFastFoodOutline />
            </Link>
          </div>
          <div className="menu-bars">
            <Link to="/cart">
              <IoCartOutline />
            </Link>
          </div>
          <div className="menu-bars-text">{cartCount}</div>
          <div className="menu-bars">
            <Link to="/login">
              <IoPersonOutline />
            </Link>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cartItems,
  };
};

export default connect(mapStateToProps)(Navbar);
