import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTimetablesCategory as listTimetablesCategory } from "../redux/actions/timetableActions";
import CartItem from "../components/CartItem";
import jwt from "jsonwebtoken";
import "./Cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const { store } = useSelector((state) => state.selectStore);
  const [timeoutTimetableCategories, setTimeoutTimetableCategories] =
    useState(undefined);

  useEffect(() => {
    if (
      store === undefined ||
      store.code === undefined ||
      store.service === undefined ||
      store.service.type === undefined
    ) {
      history.push("/");
    } else {
      dispatch(listTimetablesCategory(store.code));
      dispatch(getTimeoutTimetablesCategories);
    }
  }, []);

  const isUserAuthenticated = () => {
    const user = jwt.decode(localStorage.getItem("authToken"));
    return user !== null && user !== undefined;
  };

  const cartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const cartSubTotal = () => {
    return cartItems
      .reduce((price, item) => price + item.price * item.qty, 0)
      .toFixed(2);
  };

  const getTimeoutTimetablesCategories = async () => {
    const { data } = await axios.get("/api/timetables/categories/timeout", {
      type: "HomeBurger",
    });
    if (data !== undefined) {
      setTimeoutTimetableCategories(data);
    }
  };

  const buildKey = (cartItem) => {
    if (cartItem.supplements !== undefined) {
      const supplementIds = cartItem.supplements
        .sort((firstItem, secondItem) =>
          Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
        )
        .map((supplement) => supplement._id)
        .join("");

      return cartItem.id + supplementIds;
    } else {
      return cartItem.id;
    }
  };

  const isOutOfTime = async (type) => {
    if (
      store !== undefined &&
      store.service !== undefined &&
      store.service.type === "restaurant" &&
      timeoutTimetableCategories !== undefined
    ) {
      const outOfTime = timeoutTimetableCategories.filter(
        async (timetableCategory) => (await timetableCategory.type) === type
      );

      return outOfTime !== undefined && outOfTime.length > 0;
    }
  };

  const emptyTimeoutCategories = () => {
    return (
      store !== undefined &&
      store.service !== undefined &&
      (store.service.type !== "restaurant" ||
        (store.service.type === "restaurant" &&
          timeoutTimetableCategories !== undefined &&
          timeoutTimetableCategories.length === 0))
    );
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">
          SUBTOTAL COMANDA: {cartSubTotal()} € <br />
        </h6>
        <div>
          {cartCount() &&
          cartSubTotal() > 0 &&
          isUserAuthenticated() &&
          emptyTimeoutCategories() ? (
            <Link to={"/timetables"} style={{ textDecoration: "none" }}>
              <div className="d-grid gap-2">
                <Button variant="primary" size="lg">
                  CONTINUAR
                </Button>
              </div>
            </Link>
          ) : (
            <div className="d-grid gap-2">
              <Button disabled style={{ pointerEvents: "none" }} size="lg">
                CONTINUAR
              </Button>
            </div>
          )}
          {isUserAuthenticated() ? (
            <div />
          ) : (
            <div className="text-center text-danger">
              Cal estar registrat per continuar
            </div>
          )}
        </div>
        {cartItems.map((cartItem) => (
          <>
            {isOutOfTime(cartItem.type) ? (
              <CartItem
                key={buildKey(cartItem)}
                item={cartItem}
                timeoutCategories={timeoutTimetableCategories}
              />
            ) : (
              <CartItem key={buildKey(cartItem)} item={cartItem} />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Cart;
