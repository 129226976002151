import * as actionTypes from "../constants/userConstants";
import axios from "axios";

export const getUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_USER_REQUEST });
    const { data } = await axios.get(`/api/users/${user.id}`);

    dispatch({
      type: actionTypes.GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

    const { data } = await axios.put(`/api/users/${user.id}`, {
      email: user.email,
      username: user.username,
      phone: user.phone,
    });

    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return error;
  }
};
