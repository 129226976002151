import React from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { getTimetablesCategory as listTimetablesCategory } from "../redux/actions/timetableActions";
import { Col } from "react-bootstrap";
import "./Menu.css";

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { store } = useSelector((state) => state.selectStore);
  const getTimetablesCategory = useSelector(
    (state) => state.getTimetablesCategory
  );
  const { timetablesCategory, loading } = getTimetablesCategory;

  useEffect(() => {
    if (
      store === undefined ||
      store.code === undefined ||
      store.service === undefined ||
      store.service.type === undefined
    ) {
      history.push("/");
    } else {
      dispatch(listTimetablesCategory(store.code));
    }
  }, []);

  const menuOptions = [
    {
      type: "HomeBurger",
      description: "Hamburgueses de la casa",
    },
    {
      type: "CustomerBurger",
      description: "Hamburgueses al gust",
    },
    {
      type: "Tapa",
      description: "Tapes",
    },
    {
      type: "Salad",
      description: "Amanides",
    },
    {
      type: "Sandwich",
      description: "Entrepans",
    },
    {
      type: "Drink",
      description: "Begudes",
    },
    {
      type: "Dessert",
      description: "Postres",
    },
    {
      type: "Coffee",
      description: "Cafeteria",
    },
  ];

  const isOutOfTime = (type) => {
    if (
      store !== undefined &&
      store.service !== undefined &&
      store.service.type === "restaurant" &&
      timetablesCategory !== undefined &&
      timetablesCategory.length > 0
    ) {
      return timetablesCategory
        .filter((timetableCategory) => timetableCategory.type === type)
        .find((timetableCategory) => isTimeOut(timetableCategory));
    } else {
      return false;
    }
  };

  const replacedTime = (timetableCategory) => {
    return timetableCategory.replaceAll(":", "");
  };

  const getCurrentTimeCode = () => {
    var date = new Date();
    //date = new Date(date.getTime() + 2550 * 60000);
    const timeInSeconds = new Date(date);
    return (
      ("0" + timeInSeconds.getHours()).slice(-2) +
      ("0" + timeInSeconds.getMinutes()).slice(-2)
    );
  };

  const isTimeOut = (timetableCategory) => {
    const init = replacedTime(timetableCategory.initTime);
    const end = replacedTime(timetableCategory.endTime);
    var currentTimetableCode = getCurrentTimeCode();
    return (
      parseInt(init) > parseInt(currentTimetableCode) ||
      parseInt(end) < parseInt(currentTimetableCode)
    );
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">LA CARTA</h6>
        {loading ? (
          <div className="spinner-border m-5 text-center" role="status">
            <span className="sr-only" />
          </div>
        ) : (
          <ListGroup as={Col} className="col-12">
            {menuOptions.map((option) => (
              <ListGroup.Item
                as="li"
                key={option.description}
                className="line-item"
              >
                {isOutOfTime(option.type) ? (
                  <>
                    {option.description}
                    <a
                      className="text-center text-danger"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      (Fora horari)
                    </a>
                  </>
                ) : (
                  <>
                    {option.type === "CustomerBurger" ? (
                      <Link
                        to={"/customBurger"}
                        style={{ textDecoration: "none" }}
                      >
                        {option.description}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: "/products/search",
                          state: {
                            active: true,
                            type: option.type,
                            store: store,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        {option.description}
                      </Link>
                    )}
                  </>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
};

export default Menu;
