import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { selectStore } from "../redux/actions/storeActions";
import "./ServiceType.css";

const ServiceType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { store } = useSelector((state) => state.selectStore);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {
    if (
      cartItems !== undefined &&
      cartItems.length > 0 &&
      store !== undefined &&
      store.code !== undefined &&
      store.service !== undefined &&
      store.service.type !== undefined
    ) {
      history.push("/menu");
    }
  });

  const chooseTakeAwayStore = (store) => {
    dispatch(
      selectStore(store, {
        type: "take away",
        description: "recollir al local",
      })
    );
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">QUE VOLS DEMANAR?</h6>
        <ListGroup as="ul">
          {store.takeAway === "1" ? (
            <ListGroup.Item as="li">
              <Link
                to="/menu"
                style={{ textDecoration: "none" }}
                onClick={() => chooseTakeAwayStore(store)}
              >
                Menjar per emportar
              </Link>
            </ListGroup.Item>
          ) : (
            <></>
          )}

          {store.inRestaurant === "1" ? (
            <ListGroup.Item as="li">
              <Link to="/eatInRestaurant" style={{ textDecoration: "none" }}>
                Menjar al restaurant
              </Link>
            </ListGroup.Item>
          ) : (
            <></>
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default ServiceType;
