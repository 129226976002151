import beive_logo from "../images/beive_logo.png";
import "./Payment.css";

const PaymentFailure = () => {
  return (
    <div className="mb-3 paymentMessage">
      <p>No s'ha pogut fer el pagament. </p>

      <p>
        Pots torna-ho a intentar en uns minuts o fer la teva comanda en persona.
      </p>
      <p>Agraïm la teva paciència.</p>
      <img src={beive_logo} alt="" />
    </div>
  );
};

export default PaymentFailure;
