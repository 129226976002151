import * as actionTypes from "../constants/storeConstants";

export const getStoresReducer = (state = { stores: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_STORES_REQUEST:
      return {
        loading: true,
        stores: [],
      };
    case actionTypes.GET_STORES_SUCCESS:
      return {
        loading: false,
        stores: action.payload,
      };
    case actionTypes.GET_STORES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getStoreReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_STORES_REQUEST:
      return {
        loading: true,
        store: {},
      };
    case actionTypes.GET_STORES_SUCCESS:
      return {
        loading: false,
        store: action.payload,
      };
    case actionTypes.GET_STORES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const selectStoreReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case actionTypes.SELECT_STORE:
      return { store: action.payload, lastUpdate: Date.now() };

    case actionTypes.CLEAR_STORE:
      return {
        ...state,
        store: {},
      };
    default:
      if (Date.now() > state.lastUpdate + 1000 * 3600 * 4) {
        return {
          ...state,
          store: {},
        };
      }

      return state;
  }
};

export const updateStoreReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STORE_REQUEST:
      return {
        loading: true,
        stores: [],
      };
    case actionTypes.UPDATE_STORE_SUCCESS:
      return {
        loading: false,
        stores: action.payload,
      };
    case actionTypes.UPDATE_STORE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
