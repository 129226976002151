import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import jwt from "jsonwebtoken";
import "./Backoffice.css";

const Backoffice = () => {
  const history = useHistory();
  const [role, setRole] = useState();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      const token = jwt.decode(localStorage.getItem("authToken"));
      setRole(token.role);
      setStores(token.stores);

      if (token.role !== "worker" && token.role !== "manager") {
        history.push("/");
      }
    }
  }, [history]);

  return (
    <div>
      {role === "manager" ? (
        <div className="card">
          <h6 className="card-header">ADMINISTRACIO</h6>
          <ListGroup as="ul">
            <ListGroup.Item as="li" className="menuItems">
              <Link to="/income" style={{ textDecoration: "none" }}>
                Resum ingressos
              </Link>
            </ListGroup.Item>{" "}
            <ListGroup.Item as="li" className="menuItems">
              <Link to="/sales" style={{ textDecoration: "none" }}>
                Ventes
              </Link>
            </ListGroup.Item>
            <ListGroup.Item as="li" className="menuItems">
              <Link
                to={{
                  pathname: `/orders/search`,
                }}
                style={{ textDecoration: "none" }}
              >
                Buscador de comandes
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </div>
      ) : (
        <></>
      )}
      {(role === "worker" || role === "manager") && stores !== undefined
        ? stores.map((store) => (
            <Accordion key={store.code}>
              <Accordion.Item key={store.code}>
                <Accordion.Header key={store.code}>
                  {store.name}
                </Accordion.Header>
                <Accordion.Body>
                  <ListGroup as="ul">
                    <ListGroup.Item as="li" className="menuItems">
                      <Link
                        to={{ pathname: `/stores/${store.code}` }}
                        style={{ textDecoration: "none" }}
                      >
                        Configuració
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="menuItems">
                      <Link
                        to={{
                          pathname: `/orders`,
                          state: {
                            store: store.code,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        Comandes
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="menuItems">
                      <Link
                        to={{
                          pathname: `/stock`,
                          state: {
                            store: store.code,
                            type: "product",
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        Stock de productes
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="menuItems">
                      <Link
                        to={{
                          pathname: `/stock`,
                          state: {
                            store: store.code,
                            type: "ingredient",
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        Stock d'ingredients
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))
        : ""}
    </div>
  );
};

export default Backoffice;
