import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import "./Login.css";
import jwt from "jsonwebtoken";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      history.push("/profile");
    }
  }, [history]);

  const loginHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        "/api/auth/login",
        { email, password },
        config
      );
      if (data.success === true) {
        setError(undefined);
        localStorage.setItem("authToken", data.token);
        history.push("/profile");
        const user = jwt.decode(data.token);
        if (
          user !== undefined &&
          (user.role === "worker" || user.role === "manager")
        ) {
          window.location.reload();
        }
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <div className="card">
      <h6 className="card-header">LOGIN</h6>
      <br />
      <Form onSubmit={loginHandler}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              required
              className="text-center"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Contrasenya:</Form.Label>
            <Form.Control
              required
              className="text-center"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
          </Form.Group>
        </Row>
        <div className="d-grid gap-2">
          <Button type="submit" size="lg">
            LOGIN
          </Button>
        </div>
        <div className="login-register">
          <p />
          <Link to="/forgotpassword">Has oblidat la contrasenya?</Link>
          <p />
          No tens un compte? <Link to="/register">Registre</Link>
          <p />
        </div>
      </Form>
      <br />
      {error !== undefined ? (
        <p className="text-center text-danger">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Login;
