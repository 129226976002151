import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import jwt from "jsonwebtoken";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getSales as listSales } from "../redux/actions/orderActions";
import "react-datepicker/dist/react-datepicker.css";

const Sales = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [role, setRole] = useState();
  const [fromDate, setFromDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [toDate, setToDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [paymentType, setPaymentType] = useState();
  const [store, setStore] = useState();
  const getSales = useSelector((state) => state.getSales);
  const { sales, loading, error } = getSales;
  const status = ["Pagat", "Rebuda"];
  const days = ["Dg", "Dl", "Dm", "Dx", "Dj", "Dv", "Ds"];
  const months = [
    "Gener",
    "Febrer",
    "Març",
    "Abril",
    "Maig",
    "Juny",
    "Juliol",
    "Agost",
    "Setembre",
    "Octubre",
    "Novembre",
    "Desembre",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    if (!token || token.role !== "manager") {
      history.push("/login");
    } else {
      setRole(token.role);
      dispatch(listSales({ store, fromDate, toDate, status, paymentType }));
    }
  }, [history, fromDate, toDate]);

  const calculateSales = () => {
    dispatch(listSales({ store, fromDate, toDate, status, paymentType }));
  };

  const paymentChange = (event) => {
    event.target.value !== "undefined"
      ? setPaymentType(event.target.value)
      : setPaymentType();
  };

  const storeChange = (event) => {
    event.target.value !== "undefined"
      ? setStore(event.target.value)
      : setStore();
  };

  return (
    <div>
      {role === "manager" ? (
        <div className="card">
          <h6 className="card-header">RESUM VENTES</h6>
          <div className="text-center">
            <Form className="incomeFilters">
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <Form.Label>Local: </Form.Label>
                  <select defaultValue={undefined} onChange={storeChange}>
                    <option value="undefined">Qualsevol</option>
                    <option value="manlleu">Beive Manlleu</option>
                    <option value="vic">Beive Vic</option>
                    <option value="megaquina">Beive Megaquina</option>
                  </select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Pagament: </Form.Label>
                  <select defaultValue={undefined} onChange={paymentChange}>
                    <option value="undefined">Qualsevol</option>
                    <option value="Targeta">Targeta</option>
                    <option value="Bizum">Bizum</option>
                  </select>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <Form.Label>Data inici: </Form.Label>
                  <DatePicker
                    locale={locale}
                    className="text-center"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="dd/MM/yy 00:00:00"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Data fi: </Form.Label>
                  <DatePicker
                    locale={locale}
                    className="text-center"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="dd/MM/yy 23:59:59"
                  />
                </Form.Group>
              </Row>
              <Button onClick={calculateSales}>BUSCAR</Button>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th className="text-center">Producte</th>
                    <th className="text-center">Ventes</th>
                    <th className="text-center">Quant</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <div className="spinner-border m-5" role="status">
                          <span className="sr-only" />
                        </div>
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td>{error}</td>
                    </tr>
                  ) : (
                    sales
                      .sort((firstSale, secondSale) =>
                        firstSale.price > secondSale.price ? -1 : 1
                      )
                      .map((sale) => (
                        <tr>
                          <td className="text-center">{sale.item}</td>
                          <td className="text-center">
                            {sale.price.toFixed(2)}€
                          </td>
                          <td className="text-center">{sale.quantity}</td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
              <div className="text-center">
                {sales !== undefined && sales.length < 1 && !loading
                  ? "No hi ha resultats"
                  : ""}
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Sales;
