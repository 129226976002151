import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser as listUser, updateUser } from "../redux/actions/userActions";
import Button from "react-bootstrap/Button";
import jwt from "jsonwebtoken";
import { Form, Row, Col } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";

const MyUser = () => {
  const dispatch = useDispatch();
  const getUser = useSelector((state) => state.getUser);
  var { user, loading, error } = getUser;
  const [phone, setPhone] = useState();
  const [username, setUsername] = useState();
  const [updateResponse, setUpdateResponse] = useState();

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    dispatch(listUser(token));
  }, [dispatch]);

  const handlePhoneChange = (event) => {
    event.target.value = event.target.value.replace(/\D/gi, "");
    if (event.target.value.length > 16) {
      event.target.value = event.target.value.slice(0, 16);
    }
    setPhone(event.target.value);
  };

  const handleUsernameChange = (event) => {
    if (event.target.value.length > 16) {
      event.target.value = event.target.value.slice(0, 16);
    }
    setUsername(event.target.value);
  };

  const saveUser = () => {
    dispatch(
      updateUser({
        username: username !== undefined ? username : user.username,
        email: user.email,
        phone: phone !== undefined ? phone : user.phone,
      })
    ).then((response) => {
      response.message === "OK"
        ? setUpdateResponse("OK")
        : setUpdateResponse("KO");
    });
  };

  const pointsHelp = (props) => (
    <Tooltip {...props}>
      A partir de 100 punts, es podran bescanviar per un descompte abans de fer
      el pagament
    </Tooltip>
  );

  return (
    <div className="card">
      <h6 className="card-header">CONFIGURACIÓ</h6>
      <p />
      <div>
        {loading ? (
          <div className="spinner-border m-5" role="status">
            <span className="sr-only" />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : user !== undefined ? (
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Nom d'usuari: </Form.Label>
                <Form.Control
                  className="text-center"
                  defaultValue={user.username}
                  onChange={handleUsernameChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Telèfon:</Form.Label>
                <Form.Control
                  className="text-center"
                  onChange={handlePhoneChange}
                  defaultValue={user.phone}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Email:</Form.Label>
                <Form.Label className="text-center">{user.email}</Form.Label>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Punts:{" "}
                  <OverlayTrigger placement="top" overlay={pointsHelp}>
                    <a>
                      <BsInfoCircle />
                    </a>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Label className="text-center">{user.points}</Form.Label>
              </Form.Group>
            </Row>
            <div className="d-grid gap-2">
              <Button size="lg" onClick={saveUser}>
                GUARDAR
              </Button>
            </div>
            {updateResponse !== undefined ? (
              <div>
                <br />
                {updateResponse === "OK" ? (
                  <p className="text-center text-success">
                    S'ha actualitzat correctament
                  </p>
                ) : (
                  <p className="text-center text-danger">
                    No s'ha pogut actualitzar, prova-ho més tard
                  </p>
                )}
              </div>
            ) : (
              <></>
            )}
          </Form>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MyUser;
