import "./CustomBurger.css";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import { getIngredients as listIngredients } from "../redux/actions/ingredientActions";
import { addCustomBurgerToCart } from "../redux/actions/cartActions";
import Accordion from "react-bootstrap/Accordion";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const CustomBurger = () => {
  const dispatch = useDispatch();
  const getIngredients = useSelector((state) => state.getIngredients);
  const { store } = useSelector((state) => state.selectStore);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { ingredients } = getIngredients;
  var selectedIngredients = [];
  const radioVariables = [
    "Tria un tipus de pa",
    "Tria una hamburguesa",
    "Cocció",
  ];
  const ingredientTypes = [
    "Tria un tipus de pa",
    "Hi vols alguna base?",
    "Tria una hamburguesa",
    "Cocció",
    "Hi vols formatge?",
    "Tria els toppings que vulguis",
    "Hi vols alguna salsa?",
  ];
  const [burgerName, setburgerName] = useState("");

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
    }

    dispatch(listIngredients({ active: true, store: store.code }));
  }, [dispatch, isLoading, errorMessage, store]);

  const handleburgerName = (event) => {
    if (event.target.value.length > 10) {
      event.target.value = event.target.value.slice(0, 10);
    }
    setburgerName(event.target.value);
  };

  const modifyValue = (ingredient) => {
    if (radioVariables.includes(ingredient.type)) {
      selectedIngredients = selectedIngredients.filter(
        (item) => item.type !== ingredient.type
      );
    }

    if (selectedIngredients.find((item) => item === ingredient)) {
      selectedIngredients = selectedIngredients.filter(
        (item) => item !== ingredient
      );
    } else {
      selectedIngredients = [...selectedIngredients, ingredient];
    }
    selectedIngredients = selectedIngredients.sort((firstItem, secondItem) =>
      Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
    );
  };

  const hasBread = (selectedIngredients) => {
    return selectedIngredients !== undefined
      ? selectedIngredients.find(
          (ingredient) => ingredient.type === "Tria un tipus de pa"
        )
      : undefined;
  };

  const addToCartHandler = () => {
    if (selectedIngredients.length < 1) {
      setErrorMessage("Cal afegir algun ingredient");
    }
    if (selectedIngredients.length > 0) {
      if (!hasBread(selectedIngredients)) {
        setErrorMessage("Cal seleccionar una opció de tipus de pa");
        return;
      }
      const data = {
        burgerName: burgerName,
        description: selectedIngredients
          .sort((firstItem, secondItem) =>
            Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
          )
          .map((ingredient) => ingredient.name)
          .join(", "),
        supplements: selectedIngredients,
        price: selectedIngredients
          .reduce((price, ingredient) => price + Number(ingredient.price), 0)
          .toFixed(2),
      };

      if (data.price > 0) {
        dispatch(addCustomBurgerToCart(data));
        toast.success("Afegit!", { autoClose: 900 });
        setLoading(true);
        setburgerName("");
      }

      setErrorMessage("");
    }
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">PERSONALITZA LA TEVA BURGER</h6>

        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Posa-li nom (opcional): </Form.Label>
              <Form.Control
                className="text-center"
                onChange={handleburgerName}
                value={burgerName}
              />
            </Form.Group>
          </Row>
        </Form>
        {ingredientTypes !== undefined
          ? ingredientTypes.map((name) => (
              <Accordion key={name}>
                <Accordion.Item key={name} eventKey="0">
                  <Accordion.Header key={name}>{name}</Accordion.Header>
                  <Accordion.Body>
                    {ingredients !== undefined
                      ? ingredients
                          .filter(
                            (ingredient) =>
                              name === ingredient.type &&
                              ingredient.active === true
                          )
                          .sort((firstItem, secondItem) =>
                            Number(firstItem.order) > Number(secondItem.order)
                              ? 1
                              : -1
                          )
                          .map((ingredient, idx) => (
                            <div
                              key={`default-${ingredient.type}-${idx}`}
                              className="mb-3"
                            >
                              <Form.Check
                                type={
                                  radioVariables.includes(ingredient.type)
                                    ? "radio"
                                    : "checkbox"
                                }
                                id={`default-${ingredient.type}-${idx}`}
                                label={`${ingredient.name} (${ingredient.price}€)`}
                                onChange={(e) => {
                                  modifyValue(ingredient);
                                }}
                                name={`${ingredient.type}`}
                              />
                            </div>
                          ))
                      : ""}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
          : ""}
      </div>
      {errorMessage !== "" ? (
        <div className="text-danger text-center">{errorMessage}</div>
      ) : (
        ""
      )}
      <div className="d-grid gap-2">
        <Button size="lg" onClick={() => addToCartHandler()}>
          AFEGIR
        </Button>
      </div>
    </div>
  );
};

export default CustomBurger;
