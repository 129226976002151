import { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";

import "./ResetPassword.css";

const ResetPassword = ({ history, match }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmPassword) {
      setError("Les contrasenyes no coincideixen");
      setSuccess(undefined);
      return;
    }
    if (password.length < 6) {
      setError("La contrasenya ha de tenir almenys 6 caràcters");
      setSuccess(undefined);
      return;
    }

    try {
      const { data } = await axios.put(
        `/api/auth/passwordreset/${match.params.resetToken}`,
        {
          password,
        },
        config
      );
      if (data.success === true) {
        setSuccess(data.message);
        setError(undefined);
      } else {
        setError(data.message);
        setSuccess(undefined);
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <div className="card">
      <h6 className="card-header">REINICI CONTRASENYA</h6>
      <br />
      <Form onSubmit={resetPasswordHandler}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nova contrasenya:</Form.Label>
            <Form.Control
              required
              type="password"
              className="text-center"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Confirmar nova contrasenya:</Form.Label>
            <Form.Control
              required
              type="password"
              className="text-center"
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>
        </Row>
        <div className="d-grid gap-2">
          <Button type="submit" size="lg">
            REINICIAR CONTRASENYA
          </Button>
        </div>
      </Form>
      <br />
      {success !== undefined ? (
        <p className="text-center text-success">{success}</p>
      ) : (
        <></>
      )}
      {error !== undefined ? (
        <p className="text-center text-danger">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ResetPassword;
