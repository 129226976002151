import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import jwt from "jsonwebtoken";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getOrders as listOrders } from "../redux/actions/orderActions";
import "react-datepicker/dist/react-datepicker.css";
import "./Backoffice.css";
import "./Income.css";

const Income = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [role, setRole] = useState();
  const [fromDate, setFromDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [toDate, setToDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [paymentType, setPaymentType] = useState();
  const [store, setStore] = useState();
  const getOrders = useSelector((state) => state.getOrders);
  const { orders, loading, error } = getOrders;
  const status = ["Pagat", "Rebuda"];
  const days = ["Dg", "Dl", "Dm", "Dx", "Dj", "Dv", "Ds"];
  const months = [
    "Gener",
    "Febrer",
    "Març",
    "Abril",
    "Maig",
    "Juny",
    "Juliol",
    "Agost",
    "Setembre",
    "Octubre",
    "Novembre",
    "Desembre",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    if (!token || token.role !== "manager") {
      history.push("/login");
    } else {
      setRole(token.role);
      dispatch(listOrders({ store, fromDate, toDate, status, paymentType }));
    }
  }, [history, fromDate, toDate]);

  const searchIncome = () => {
    dispatch(listOrders({ store, fromDate, toDate, status, paymentType }));
  };

  const paymentChange = (event) => {
    event.target.value !== "undefined"
      ? setPaymentType(event.target.value)
      : setPaymentType();
  };

  const storeChange = (event) => {
    event.target.value !== "undefined"
      ? setStore(event.target.value)
      : setStore();
  };

  const refund = (order) => {
    return (order.refund !== undefined ? Number(order.refund) : 0.0).toFixed(2);
  };

  return (
    <div>
      {role === "manager" ? (
        <div className="card">
          <h6 className="card-header">RESUM INGRESSOS</h6>
          <div className="text-center">
            <Form className="incomeFilters">
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <Form.Label>Local: </Form.Label>
                  <select defaultValue={undefined} onChange={storeChange}>
                    <option value="undefined">Qualsevol</option>
                    <option value="manlleu">Beive Manlleu</option>
                    <option value="vic">Beive Vic</option>
                    <option value="megaquina">Beive Megaquina</option>
                  </select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Pagament: </Form.Label>
                  <select defaultValue={undefined} onChange={paymentChange}>
                    <option value="undefined">Qualsevol</option>
                    <option value="Targeta">Targeta</option>
                    <option value="Bizum">Bizum</option>
                  </select>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <Form.Label>Data inici: </Form.Label>
                  <DatePicker
                    locale={locale}
                    className="text-center"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="dd/MM/yy 00:00:00"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Data fi: </Form.Label>
                  <DatePicker
                    locale={locale}
                    className="text-center"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="dd/MM/yy 23:59:59"
                  />
                </Form.Group>
              </Row>
              <Button onClick={searchIncome}>BUSCAR</Button>
              <br />
              {loading ? (
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only" />
                </div>
              ) : error ? (
                <div>{error}</div>
              ) : (
                <Row className="incomeResults">
                  <Form.Label>
                    Base imponible:{" "}
                    {orders !== undefined
                      ? (
                          orders.reduce(
                            (total, order) =>
                              (total =
                                Number(total) +
                                Number(order.totalAmount) -
                                refund(order)),
                            0.0
                          ) *
                          (10 / 11)
                        ).toFixed(2)
                      : ""}
                    €
                  </Form.Label>{" "}
                  <Form.Label>
                    Quota IVA (10%):{" "}
                    {orders !== undefined
                      ? (
                          orders.reduce(
                            (total, order) =>
                              (total =
                                Number(total) +
                                Number(order.totalAmount) -
                                refund(order)).toFixed(2),
                            0.0
                          ) *
                          (1 / 11)
                        ).toFixed(2)
                      : ""}
                    €
                  </Form.Label>{" "}
                  <Form.Label>
                    Total:{" "}
                    {orders !== undefined
                      ? orders
                          .reduce(
                            (total, order) =>
                              (total =
                                Number(total) +
                                Number(order.totalAmount) -
                                refund(order)),
                            0.0
                          )
                          .toFixed(2)
                      : ""}
                    €
                  </Form.Label>{" "}
                </Row>
              )}
            </Form>
          </div>
          <br />
          <div className="card incomeResults">
            <Form className="incomeFilters">
              <Form.Label>
                Total número comandes:{" "}
                {orders !== undefined ? orders.length : "0"}
              </Form.Label>
              <Form.Label>
                Total import comandes:{" "}
                {orders !== undefined
                  ? orders
                      .reduce(
                        (total, order) =>
                          (total = Number(total) + Number(order.totalAmount)),
                        0.0
                      )
                      .toFixed(2)
                  : ""}
                €
              </Form.Label>
              <Form.Label>
                Total devolucions:{" "}
                {orders !== undefined
                  ? orders
                      .reduce(
                        (total, order) =>
                          (total = Number(total) + Number(refund(order))),
                        0.0
                      )
                      .toFixed(2)
                  : ""}
                €
              </Form.Label>
              <Form.Label>
                Total propines:{" "}
                {orders !== undefined
                  ? orders
                      .reduce(
                        (total, order) =>
                          (total = Number(total) + Number(order.tip)),
                        0.0
                      )
                      .toFixed(2)
                  : ""}
                €
              </Form.Label>
              <Form.Label>
                Total costos gestió:{" "}
                {orders !== undefined
                  ? orders
                      .reduce(
                        (total, order) =>
                          (total =
                            Number(total) +
                            Number(order.cost) +
                            (order.hiddenCost !== undefined
                              ? Number(order.hiddenCost)
                              : 0)),
                        0.0
                      )
                      .toFixed(2)
                  : ""}
                €
              </Form.Label>
              <Form.Label>
                Total descomptes:{" "}
                {orders !== undefined
                  ? orders
                      .reduce(
                        (total, order) =>
                          (total = Number(total) + Number(order.discount)),
                        0.0
                      )
                      .toFixed(2)
                  : ""}
                €
              </Form.Label>
            </Form>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Income;
