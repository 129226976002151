import React, { useEffect, useRef } from "react";
import { PaymentBuilder, RedsysBuilder } from "../redsys";

const Redsys = (data) => {
  const commerce_code = "358365385";
  const secret_code = "rmBrRPAxGMdAGyoV32eP7jzLocrC5bCY";
  const payform = useRef();
  const orderNumber = data.location.orderNumber;
  const totalAmount = data.location.totalAmount;
  const paymentMethod = data.location.paymentMethod;

  useEffect(() => {
    payform.current.submit();
  }, []);

  const redsys = new RedsysBuilder()
    .setMerchantCode(commerce_code)
    .setSecret(secret_code)
    .setName("Beive")
    //.enableDebug()
    .build();

  const paymentData = new PaymentBuilder()
    .setTotal(totalAmount)
    .setOrderId(orderNumber)
    .setUrlMerchant("https://beiveonline.herokuapp.com/api/payments")
    .setUrlCancel("https://app.beiveonline.com/payment/failure")
    .setUrlOK("https://app.beiveonline.com/payment/success")
    .setPayMethods(paymentMethod)
    .build();

  const form_encoded_params = redsys.getFormData(paymentData);

  return (
    <div>
      <form action={form_encoded_params.redsys_url} method="POST" ref={payform}>
        <input
          type="hidden"
          name="Ds_SignatureVersion"
          value={form_encoded_params.Ds_SignatureVersion}
        />
        <input
          type="hidden"
          name="Ds_MerchantParameters"
          value={form_encoded_params.Ds_MerchantParameters}
        />
        <input
          type="hidden"
          name="Ds_Signature"
          value={form_encoded_params.Ds_Signature}
        />
      </form>
    </div>
  );
};

export default Redsys;
