import * as actionTypes from "../constants/timetableConstants";

export const getTimetableReducer = (state = { timetables: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_TIMETABLES_REQUEST:
      return {
        loading: true,
        timetables: [],
      };
    case actionTypes.GET_TIMETABLES_SUCCESS:
      return {
        loading: false,
        timetables: action.payload,
      };
    case actionTypes.GET_TIMETABLES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getTimetableCategoryReducer = (
  state = { timetablesCategory: [] },
  action
) => {
  switch (action.type) {
    case actionTypes.GET_TIMETABLES_CATEGORY_REQUEST:
      return {
        loading: true,
        timetablesCategory: [],
      };
    case actionTypes.GET_TIMETABLES_CATEGORY_SUCCESS:
      return {
        loading: false,
        timetablesCategory: action.payload,
      };
    case actionTypes.GET_TIMETABLES_CATEGORY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
