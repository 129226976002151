import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTimetables as listTimetables } from "../redux/actions/timetableActions";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

const Timetables = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getTimetables = useSelector((state) => state.getTimetables);
  const selectedStore = useSelector((state) => state.selectStore);
  const { store } = selectedStore;
  const { timetables } = getTimetables;
  const [selectedTimeTable, setSelectedTimeTable] = useState();

  useEffect(() => {
    if (
      store === undefined ||
      store.code === undefined ||
      store.service === undefined ||
      store.service.type === undefined
    ) {
      history.push("/");
    }

    if (store.service.type === "restaurant") {
      history.push("/checkout");
    } else {
      dispatch(listTimetables(store));
    }
  }, [dispatch, history, store]);

  const handleTimetable = (timetable) => {
    setSelectedTimeTable(timetable);
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">SELECCIONA HORARI DE RECOLLIDA</h6>
        <Accordion key="timetables">
          <Accordion.Item key="timetables">
            <Accordion.Body>
              {timetables !== undefined && timetables.length > 0
                ? timetables
                    .sort((firstItem, secondItem) =>
                      firstItem.timetable > secondItem.timetable ? 1 : -1
                    )
                    .map((item, idx) => (
                      <div
                        key={`default-${item.timetable}-${idx}`}
                        className="mb-3"
                      >
                        <Form.Check
                          type="radio"
                          id={`default-${item.timetable}-${idx}`}
                          label={`${item.description}${
                            item.maxQuantity < 3 ? " - (Últimes places)" : ""
                          }`}
                          onChange={(e) => {
                            handleTimetable(item);
                          }}
                          name={"timetable"}
                        />
                      </div>
                    ))
                : "No hi ha horaris disponibles"}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div>
          {selectedTimeTable !== undefined ? (
            <Link
              to={{
                pathname: "/checkout",
                timetable: selectedTimeTable,
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="d-grid gap-2">
                <Button variant="primary" size="lg">
                  CONTINUAR
                </Button>
              </div>
            </Link>
          ) : (
            <div className="d-grid gap-2">
              <Button disabled style={{ pointerEvents: "none" }} size="lg">
                CONTINUAR
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timetables;
