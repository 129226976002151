export const GET_STORES_REQUEST = "GET_STORES_REQUEST";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAIL = "GET_STORES_FAIL";

export const SELECT_STORE = "SELECT_STORE";
export const CLEAR_STORE = "CLEAR_STORE";

export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";
