import * as actionTypes from "../constants/orderConstants";

export const getOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS_REQUEST:
      return {
        loading: true,
        orders: [],
      };
    case actionTypes.GET_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case actionTypes.GET_ORDERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getSalesReducer = (state = { sales: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_SALES_REQUEST:
      return {
        loading: true,
        sales: [],
      };
    case actionTypes.GET_SALES_SUCCESS:
      return {
        loading: false,
        sales: action.payload,
      };
    case actionTypes.GET_SALES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORDER_REQUEST:
      return {
        loading: true,
        orders: action.payload,
      };
    case actionTypes.CREATE_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case actionTypes.CREATE_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateOrderStatusReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ORDER_STATUS_REQUEST:
      return {
        loading: true,
        order: action.payload,
      };
    case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case actionTypes.UPDATE_ORDER_STATUS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
