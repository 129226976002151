import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Dialog.css";

const Dialog = ({ product, onSubmitDialog }) => {
  const supplements = product.supplements;
  const [selectedSupplements, setSelectedSupplements] = useState([]);

  const buildOption = (choose) => {
    return { choose: choose, selectedSupplements: selectedSupplements };
  };

  const modifyValue = (chosenSupplement) => {
    var filteredSupplements = [];

    if (
      selectedSupplements.find((item) => item.type === chosenSupplement.type)
    ) {
      if (chosenSupplement.isMultiChoice === "0") {
        filteredSupplements = selectedSupplements.filter(
          (item) => item.type !== chosenSupplement.type
        );
        filteredSupplements = [...filteredSupplements, chosenSupplement];
      } else if (
        selectedSupplements.find(
          (item) => item.option.name === chosenSupplement.option.name
        )
      ) {
        filteredSupplements = selectedSupplements.filter(
          (item) => item.option.name !== chosenSupplement.option.name
        );
      } else {
        filteredSupplements = [...selectedSupplements, chosenSupplement];
      }
    } else {
      filteredSupplements = [...selectedSupplements, chosenSupplement];
    }

    setSelectedSupplements(filteredSupplements);
  };

  const allTypesSelected = () => {
    const allTypes = supplements
      .filter((supplement) => supplement.isMultiChoice === "0")
      .map((supplement) => supplement.type)
      .sort((firstItem, secondItem) => (firstItem > secondItem ? 1 : -1));
    const selectedTypes = selectedSupplements
      .filter((supplement) => supplement.isMultiChoice === "0")
      .map((selectedType) => selectedType.type)
      .sort((firstItem, secondItem) => (firstItem > secondItem ? 1 : -1));
    const selectedTypeSet = [...new Set(selectedTypes)];
    const areEqual =
      allTypes.length === selectedTypeSet.length &&
      allTypes.every(
        (o, i) =>
          Object.keys(o).length === Object.keys(selectedTypeSet[i]).length &&
          Object.keys(o).every((k) => o[k] === selectedTypeSet[i][k])
      );
    return areEqual;
  };

  return (
    <div className="dialog_background">
      <div className="dialog">
        <h5 className="card-title">{product.title}</h5>
        {supplements !== undefined
          ? supplements.map((supplement) => (
              <Accordion key={supplement.type}>
                <Accordion.Item key={supplement.type} eventKey="0">
                  <Accordion.Header key={supplement.type}>
                    {supplement.type}
                  </Accordion.Header>
                  <Accordion.Body>
                    {supplement.options !== undefined
                      ? supplement.options.map((option, idx) => (
                          <div
                            key={`default-${option.type}-${idx}`}
                            className="mb-3"
                          >
                            <Form.Group className="row">
                              <Form.Check
                                type={
                                  supplement.isMultiChoice === "1"
                                    ? "checkbox"
                                    : "radio"
                                }
                                id={`default-${supplement.type}-${idx}`}
                                label={`${option.name} (${option.price}€)`}
                                className="radio"
                                onChange={(e) => {
                                  const selectedSupplement = {
                                    type: supplement.type,
                                    option: option,
                                    isMultiChoice: supplement.isMultiChoice,
                                  };
                                  modifyValue(selectedSupplement);
                                }}
                                name={`${supplement.type}`}
                              />
                            </Form.Group>
                          </div>
                        ))
                      : ""}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
          : ""}
        <div className="menu_button">
          {allTypesSelected() === true ? (
            <Button onClick={() => onSubmitDialog(buildOption(true))}>
              AFEGIR
            </Button>
          ) : (
            <Button disabled>AFEGIR</Button>
          )}
          <Button
            variant="secondary"
            onClick={() => onSubmitDialog(buildOption(false))}
          >
            CANCELAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
