import { useState } from "react";
import axios from "axios";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/auth/forgotpassword",
      { email },
      config
    );

    if (data.success === true) {
      setSuccess(data.message);
      setError(undefined);
    } else {
      setError(data.message);
      setSuccess(undefined);
    }
  };

  return (
    <div className="card">
      <h6 className="card-header">RECUPERAR CONTRASENYA</h6>
      <br />
      <Form onSubmit={forgotPasswordHandler}>
        <Row className="mb-3">
          <p className="text-center">
            <Form.Label>
              Introdueix el teu email i t'enviarem un enllaç per reiniciar la
              contrasenya. Recorda revisar la bústia de Spam o correu no
              desitjat.
            </Form.Label>
          </p>
          <Form.Group as={Col}>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              required
              className="text-center"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <div className="d-grid gap-2">
          <Button type="submit" size="lg">
            CONFIRMAR
          </Button>
        </div>
      </Form>
      <br />
      {success !== undefined ? (
        <p className="text-center text-success">{success}</p>
      ) : (
        <></>
      )}
      {error !== undefined ? (
        <p className="text-center text-danger">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ForgotPassword;
