import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import "./Register.css";

const Register = ({ history }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const handlePhoneChange = (event) => {
    event.target.value = event.target.value.replace(/\D/gi, "");
    if (event.target.value.length > 16) {
      event.target.value = event.target.value.slice(0, 16);
    }
    setPhone(event.target.value);
  };

  const handleUsernameChange = (event) => {
    if (event.target.value.length > 16) {
      event.target.value = event.target.value.slice(0, 16);
    }
    setUsername(event.target.value);
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmpassword) {
      return setError("Les contrasenyes no coincideixen");
    }

    try {
      const { data } = await axios.post(
        "/api/auth/register",
        {
          username,
          email,
          password,
          phone,
        },
        config
      );

      if (data.success === true) {
        setError(undefined);
        localStorage.setItem("authToken", data.token);
        history.push("/profile");
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  return (
    <div className="card">
      <h6 className="card-header">REGISTRE</h6>
      <br />
      <Form onSubmit={registerHandler}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nom d'usuari:</Form.Label>
            <Form.Control
              required
              className="text-center"
              onChange={handleUsernameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              required
              className="text-center"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Telèfon:</Form.Label>
            <Form.Control
              required
              className="text-center"
              onChange={handlePhoneChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Contrasenya:</Form.Label>
            <Form.Control
              required
              type="password"
              className="text-center"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Confirmar contrasenya:</Form.Label>
            <Form.Control
              required
              type="password"
              className="text-center"
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="off"
            />
          </Form.Group>
        </Row>
        <div className="d-grid gap-2">
          <Button type="submit" size="lg">
            REGISTRE
          </Button>
        </div>
        <div className="login-register">
          <p />
          Ja tens un compte? <Link to="/login">Login</Link>
          <p />
        </div>
      </Form>
      {error !== undefined ? (
        <p className="text-center text-danger">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Register;
