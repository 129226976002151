import * as actionTypes from "../constants/ingredientConstants";
import axios from "axios";

export const getIngredients = (props) => async (dispatch) => {
  const store = props !== undefined ? props.store : undefined;
  const type = props !== undefined ? props.type : undefined;
  const active = props !== undefined ? props.active : undefined;
  const id = props !== undefined ? props.id : undefined;

  try {
    dispatch({ type: actionTypes.GET_INGREDIENTS_REQUEST });

    const { data } = await axios.post("/api/ingredients/request", {
      id: id,
      store: store,
      type: type,
      active: active,
    });

    dispatch({
      type: actionTypes.GET_INGREDIENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_INGREDIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
