import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getProducts as listProducts } from "../redux/actions/productActions";
import { getIngredients as listIngredients } from "../redux/actions/ingredientActions";
import axios from "axios";
import "./Stock.css";

import Button from "react-bootstrap/Button";

const Stock = () => {
  const location = useLocation();
  const { type } = location.state;
  const { store } = location.state;
  const dispatch = useDispatch();
  const getProducts = useSelector((state) => state.getProducts);
  const getIngredients = useSelector((state) => state.getIngredients);
  const items =
    type === "product" ? getProducts.products : getIngredients.ingredients;
  const [reload, setReload] = useState(false);

  const changeAvailability = async (item) => {
    if (type === "product") {
      axios.put("/api/products/" + item.id, {
        active: item.active,
        store: store,
      });
    } else if (type === "ingredient") {
      axios.put("/api/ingredients/" + item._id, {
        active: item.active,
        store: store,
      });
    }
    setReload(!reload);
  };

  useEffect(() => {
    type === "product"
      ? dispatch(listProducts({ store }))
      : dispatch(listIngredients({ store }));
  }, [dispatch, reload, store]);

  return (
    <div>
      <h6 className="card-header">STOCK</h6>

      <div className="card align-items-left">
        <div className="row">
          <div className="card-body-srock">
            <table className="table table-striped">
              <tbody>
                {items !== undefined
                  ? items
                      .sort((firstItem, secondItem) =>
                        firstItem.name > secondItem.name ? 1 : -1
                      )
                      .map((item) => (
                        <tr key={item._id}>
                          <td>
                            <label className="form-check-label">
                              {item.name}
                            </label>
                          </td>
                          <td>
                            <input
                              className="form-check-input"
                              type="radio"
                              id={item._id}
                              checked={item.active}
                              disabled
                            />
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => {
                                changeAvailability(item);
                              }}
                            >
                              CANVIAR
                            </Button>
                          </td>
                        </tr>
                      ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
