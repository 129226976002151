import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Order from "../components/Order";
import { getOrders as listOrders } from "../redux/actions/orderActions";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import jwt from "jsonwebtoken";

const OrderSearch = () => {
  const history = useHistory();
  const [store, setStore] = useState();
  const [code, setCode] = useState();
  const [id, setId] = useState();
  const [days, setDays] = useState(1);
  const dispatch = useDispatch();
  const getOrders = useSelector((state) => state.getOrders);
  const { orders, loading, error } = getOrders;
  const status = ["Pagat", "Rebuda", "Pendent", "Rebutjat"];

  const dateFilter = () => {
    const date = new Date(
      new Date().getTime() - 1000 * 60 * 60 * 24 * Number(days)
    );
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return (
      year +
      "-" +
      ("0" + month).slice(-2) +
      "-" +
      ("0" + day).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    if (!token || (token.role !== "worker" && token.role !== "manager")) {
      history.push("/login");
    } else {
      const fromDate = dateFilter();
      dispatch(
        listOrders({
          store,
          fromDate,
          id,
          code,
          status: ["Pagat", "Rebuda", "Pendent", "Rebutjat"],
        })
      );
    }
  }, [dispatch, id, code, store, days]);

  const dateChange = (event) => {
    setDays(event.target.value);
  };

  const tableChange = (event) => {
    setCode(event.target.value);
  };

  const orderNumberChange = (event) => {
    setId(event.target.value);
  };

  const storeChange = (event) => {
    event.target.value !== "undefined"
      ? setStore(event.target.value)
      : setStore();
  };

  const searchOrders = () => {
    const fromDate = dateFilter();
    dispatch(listOrders({ store, fromDate, id, code, status }));
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">BUSCADOR DE COMANDES</h6>
        <div className="text-center">
          <Form>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Local: </Form.Label>
                <select defaultValue={undefined} onChange={storeChange}>
                  <option value="undefined">Qualsevol</option>
                  <option value="manlleu">Beive Manlleu</option>
                  <option value="vic">Beive Vic</option>
                  <option value="megaquina">Beive Megaquina</option>
                </select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Data: </Form.Label>
                <select defaultValue="1" onChange={dateChange}>
                  <option value="1">Últim dia</option>
                  <option value="7">Última setmana</option>
                  <option value="30">Últims 30 dies</option>
                  <option value="365">Últim any</option>
                </select>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Número de comanda: </Form.Label>
                <Form.Control
                  className="text-center"
                  onChange={orderNumberChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Número de taula: </Form.Label>
                <Form.Control className="text-center" onChange={tableChange} />
              </Form.Group>
            </Row>
          </Form>

          <p />
          <div className="text-center">
            <Button
              variant="primary"
              onClick={() => {
                searchOrders();
              }}
            >
              BUSCAR
            </Button>
          </div>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th className="text-center">Número</th>
                <th className="text-center">Taula</th>
                <th className="text-center">Hora</th>
                <th className="text-center">Import</th>
                <th className="text-center">Estat</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td>
                    <div className="spinner-border m-5" role="status">
                      <span className="sr-only" />
                    </div>
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td>{error}</td>
                </tr>
              ) : (
                orders.map((order) => <Order key={order._id} order={order} />)
              )}
            </tbody>
          </table>
          <div className="text-center">
            {orders !== undefined && orders.length < 1 && !loading
              ? "No hi ha resultats"
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSearch;
