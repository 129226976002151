import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateOrderStatus } from "../../redux/actions/orderActions";
import "./Ticket.css";
import Button from "react-bootstrap/Button";

const Ticket = (data) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const order = data.location.order;
  const lines = order !== undefined ? order.lines : [];

  useEffect(() => {
    if (order === undefined) {
      back();
    } else {
      window.print();
      dispatch(updateOrderStatus(order.id, "Rebuda"));
    }
  });

  const back = () => {
    history.push("/orders");
  };

  const getTime = (datetime) => {
    const newDate = new Date(datetime);
    const minutes = newDate.getMinutes();
    const hours = newDate.getHours();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear().toString().slice(-2);

    return `${date < 10 ? `0${date}` : `${date}`}/${
      month < 10 ? `0${month}` : `${month}`
    }/${year} ${hours < 10 ? `0${hours}` : `${hours}`}:${
      minutes < 10 ? `0${minutes}` : `${minutes}`
    }`;
  };

  return (
    <div>
      <div className="d-grid gap-2 d-print-none">
        <Button id="backButton" size="lg" onClick={back}>
          TORNAR ENRERE
        </Button>
        <Button id="ticketButton" size="lg" onClick={() => window.print()}>
          TICKET
        </Button>
      </div>
      <div id="invoice-POS">
        <center id="top">
          <div className="info">
            <h1>Beive</h1>
          </div>
        </center>
        <div id="mid">
          {order.store === "manlleu" ? (
            <div className="info">
              <div className="contact-info">Av. Puigmal 80</div>
              <div className="contact-info">08560 Manlleu</div>
              <div className="contact-info">Tel. 93 513 38 53</div>
              <div className="contact-info">NIF. J66839960</div>
            </div>
          ) : (
            <div className="info">
              <div className="contact-info">C/Verdaguer 5</div>
              <div className="contact-info">08500 Vic</div>
              <div className="contact-info">Tel. 93 885 33 63</div>
              <div className="contact-info">NIF. J66839960</div>
            </div>
          )}
        </div>
        <div id="bot">
          <div className="orderDate">
            {order !== undefined ? getTime(order.datetime) : ""}
          </div>
          <div className="orderNumber">
            {order !== undefined && order.service !== undefined
              ? order.service === "take away"
                ? `R-${order.id}`
                : `T-${order.code}`
              : ""}
          </div>
          <div className="orderReservedDate">
            {order !== undefined && order.time !== undefined
              ? order.time.slice(0, 5)
              : ""}
          </div>
          <div id="table">
            <table>
              <tbody>
                <tr className="tabletitle" key="header">
                  <td className="unit">
                    <h2>UNIT</h2>
                  </td>
                  <td className="description text-center">
                    <h2>DESCRIPCIO</h2>
                  </td>
                  <td className="subtotal">
                    <h2>SUB</h2>
                  </td>
                </tr>
                {lines.map((line) => (
                  <tr className="service" key={line.id}>
                    <td className="unit">{line.quantity}</td>
                    <td>
                      <div className="itemtext">
                        {line.itemTitle}
                        {line.itemCustomName !== undefined &&
                        line.itemCustomName !== "" ? (
                          ` "${line.itemCustomName}"`
                        ) : (
                          <></>
                        )}
                        <br />
                        {line.itemDescription !== undefined ? (
                          `${line.itemDescription}`
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    <td className="number">
                      <div className="itemtext">
                        {(line.price * line.quantity).toFixed(2)}€
                      </div>
                    </td>
                  </tr>
                ))}
                <tr className="tabletitle" key="rate">
                  <td></td>
                  <td className="Rate">
                    <h2>Cost gestió</h2>
                  </td>
                  <td className="number">
                    <h2>{order !== undefined ? order.cost : 0.0}€</h2>
                  </td>
                </tr>
                <tr className="tabletitle" key="rate">
                  <td></td>
                  <td className="Rate">
                    <h2>Propina</h2>
                  </td>
                  <td className="number">
                    <h2>{order !== undefined ? order.tip : 0.0}€</h2>
                  </td>
                </tr>
                <tr className="tabletitle" key="rate">
                  <td></td>
                  <td className="Rate">
                    <h2>IVA (10%)</h2>
                  </td>
                  <td className="number">
                    <h2>
                      {order !== undefined
                        ? (order.totalAmount * (1 / 11)).toFixed(2)
                        : 0.0}
                      €
                    </h2>
                  </td>
                </tr>
                <tr className="tabletitle" key="total">
                  <td></td>
                  <td className="Rate">
                    <h2>TOTAL</h2>
                  </td>
                  <td className="number">
                    <h2>{order !== undefined ? order.totalAmount : 0}€</h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
