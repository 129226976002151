import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { autoSelectStore } from "../redux/actions/storeActions";
import { clearCart } from "../redux/actions/cartActions";

const Guest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const storeCode =
    params.get("store") !== null ? params.get("store") : undefined;
  const table = params.get("table") !== null ? params.get("table") : undefined;
  const { store } = useSelector((state) => state.selectStore);

  useEffect(() => {
    if (
      store !== undefined &&
      store.code !== undefined &&
      store.service !== undefined &&
      store.service.type !== undefined &&
      store.service.type === "restaurant" &&
      store.code === storeCode
    ) {
      history.push("/menu");
    } else {
      if (storeCode !== undefined && table !== undefined) {
        dispatch(autoSelectStore({ storeCode: storeCode, table: table }));
      }
      if (
        store !== undefined &&
        (store.code !== storeCode ||
          (store.service !== undefined && store.service.type !== "restaurant"))
      ) {
        dispatch(clearCart());
      }
    }
  }, [store]);

  return <></>;
};

export default Guest;
