import * as actionTypes from "../constants/timetableConstants";
import axios from "axios";

export const getTimetables = (store) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_TIMETABLES_REQUEST });

    const { data } = await axios.get("/api/timetables", {
      params: {
        openDays: store.openDays.map((openDay) => openDay.id),
        store: store.code,
      },
    });

    dispatch({
      type: actionTypes.GET_TIMETABLES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_TIMETABLES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTimetablesCategory = (store) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_TIMETABLES_CATEGORY_REQUEST });

    const { data } = await axios.get("/api/timetables/categories", {
      params: { store: store },
    });

    dispatch({
      type: actionTypes.GET_TIMETABLES_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_TIMETABLES_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
