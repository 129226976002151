import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

const EatInRestaurant = () => {
  return (
    <div>
      <div className="card">
        <h6 className="card-header">MENJAR AL RESTAURANT</h6>
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            Escaneja el codi QR de la taula
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
};

export default EatInRestaurant;
