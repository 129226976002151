import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { cartReducer } from "./reducers/cartReducers";
import { createOrderReducer, getOrdersReducer } from "./reducers/orderReducers";
import { getSalesReducer } from "./reducers/orderReducers";
import { getProductsReducer } from "./reducers/productReducers";
import { getIngredientReducer } from "./reducers/ingredientReducers";
import {
  getStoreReducer,
  getStoresReducer,
  selectStoreReducer,
} from "./reducers/storeReducers";
import {
  getTimetableReducer,
  getTimetableCategoryReducer,
} from "./reducers/timetableReducers";
import { getUserReducer } from "./reducers/userReducers";

const reducer = combineReducers({
  cart: cartReducer,
  getProducts: getProductsReducer,
  getOrders: getOrdersReducer,
  getSales: getSalesReducer,
  createOrder: createOrderReducer,
  getIngredients: getIngredientReducer,
  getStores: getStoresReducer,
  getStore: getStoreReducer,
  selectStore: selectStoreReducer,
  getTimetables: getTimetableReducer,
  getTimetablesCategory: getTimetableCategoryReducer,
  getUser: getUserReducer,
});

const middleware = [thunk];

const cartItems = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const selectStore = localStorage.getItem("selectStore")
  ? JSON.parse(localStorage.getItem("selectStore"))
  : {};

const INITIAL_STATE = {
  cart: {
    cartItems: cartItems,
  },
  selectStore: selectStore,
};

const store = createStore(
  reducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
