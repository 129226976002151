import Button from "react-bootstrap/Button";
import "./Dialog.css";

const SimpleDialog = ({ onSubmitDialog, text, buttons }) => {
  return (
    <div className="dialog_background">
      <div className="dialog">
        <h5 className="card-title">{text} </h5>
        <br />
        <div className="menu_button">
          {buttons.map((button) => (
            <Button
              key={button}
              variant={button !== "CANCEL·LAR" ? "primary" : "secondary"}
              onClick={() => onSubmitDialog(button)}
            >
              {button}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SimpleDialog;
