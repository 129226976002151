import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreById as listStoreById,
  updateStore,
} from "../redux/actions/storeActions";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./Store.css";

const Store = () => {
  const dispatch = useDispatch();
  const getStore = useSelector((state) => state.getStore);
  const { store, loading, error } = getStore;
  const search = window.location.pathname;
  const code = search.split("/").pop();

  useEffect(() => {
    dispatch(listStoreById(code));
  }, [dispatch, code]);

  const updateActive = () => {
    store.active = Number(store.active) === 0 ? 1 : 0;
    dispatch(updateStore(store));
    window.location.reload();
  };

  const updateTakeAway = () => {
    store.takeAway = Number(store.takeAway) === 0 ? 1 : 0;
    dispatch(updateStore(store));
    window.location.reload();
  };

  const updateEatInRestaurant = () => {
    store.inRestaurant = Number(store.inRestaurant) === 0 ? 1 : 0;
    dispatch(updateStore(store));
    window.location.reload();
  };

  return (
    <div className="card">
      <h6 className="card-header">CONFIGURACIÓ</h6>
      <p />
      <div>
        {loading ? (
          <div className="spinner-border m-5" role="status">
            <span className="sr-only" />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Local: </Form.Label>
                <Form.Control
                  className="text-center"
                  defaultValue={store.name}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Direcció:</Form.Label>
                <Form.Control
                  className="text-center"
                  defaultValue={store.location}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Activar/desactivar venta a {store.name}:
                </Form.Label>
                <Row className="mb-3">
                  <div className="wrapper">
                    <center>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={store.active}
                        checked={Number(store.active) === 1}
                        disabled
                      />
                    </center>
                    <Button
                      className="center"
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        updateActive();
                      }}
                    >
                      CANVIAR
                    </Button>
                  </div>
                </Row>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Take Away</Form.Label>
                <Row className="mb-3">
                  <div className="wrapper">
                    <center>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={store.takeAway}
                        checked={Number(store.takeAway) === 1}
                        disabled
                      />
                    </center>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        updateTakeAway();
                      }}
                    >
                      CANVIAR
                    </Button>
                  </div>
                </Row>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Menjar al local</Form.Label>
                <Row className="mb-3">
                  <div className="wrapper">
                    <center>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={store.inRestaurant}
                        checked={Number(store.inRestaurant) === 1}
                        disabled
                      />
                    </center>

                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        updateEatInRestaurant();
                      }}
                    >
                      CANVIAR
                    </Button>
                  </div>
                </Row>
              </Form.Group>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Store;
