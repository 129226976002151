import * as actionTypes from "../constants/storeConstants";
import axios from "axios";

export const getStores = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_STORES_REQUEST });

    const { data } = await axios.get("/api/stores");

    dispatch({
      type: actionTypes.GET_STORES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_STORES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getStoreById = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_STORES_REQUEST });

    const { data } = await axios.get(`/api/stores/${id}`);

    dispatch({
      type: actionTypes.GET_STORES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_STORES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const selectStore =
  (store, serviceType) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.SELECT_STORE,
      payload: {
        code: store.code,
        name: store.name,
        location: store.location,
        takeAway: store.takeAway,
        inRestaurant: store.inRestaurant,
        service: serviceType,
        openDays: store.openDays,
      },
    });

    localStorage.setItem("selectStore", JSON.stringify(getState().selectStore));
  };

export const autoSelectStore = (params) => async (dispatch, getState) => {
  const code = params.storeCode;
  const service = params.table !== undefined ? "restaurant" : "take away";
  const { data } = await axios.get(`/api/stores/${code}`);

  dispatch({
    type: actionTypes.SELECT_STORE,
    payload: {
      code: data.code,
      name: data.name,
      location: data.location,
      openDays: data.openDays,
      service: {
        type: service,
        description: "menjar al restaurant",
        table: params.table,
      },
    },
  });
  localStorage.setItem("selectStore", JSON.stringify(getState().selectStore));
};

export const updateStore = (store) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_STORE_REQUEST });

    const { data } = await axios.put(`/api/stores/${store.code}`, {
      active: store.active,
      takeAway: store.takeAway,
      inRestaurant: store.inRestaurant,
    });

    dispatch({
      type: actionTypes.UPDATE_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_STORE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearStore = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLEAR_STORE,
  });

  localStorage.setItem(
    "selectStore",
    JSON.stringify(getState().selectStore.store)
  );
};
