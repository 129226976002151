import * as actionTypes from "../constants/ingredientConstants";

export const getIngredientReducer = (state = { ingredients: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_INGREDIENTS_REQUEST:
      return {
        loading: true,
        ingredients: [],
      };
    case actionTypes.GET_INGREDIENTS_SUCCESS:
      return {
        loading: false,
        ingredients: action.payload,
      };
    case actionTypes.GET_INGREDIENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
