import beive_logo from "../images/beive_logo.png";
import "./Payment.css";

const PaymentSuccess = () => {
  /*useEffect(() => {
    window.history.pushState({}, null, "/payment/success");
  }, []);*/

  return (
    <div className="mb-3 paymentMessage">
      <p>Gràcies per confiar amb Beive! </p>
      <p>Estem preparant la teva comanda.</p>
      <p>Agraïm la teva paciència.</p>
      <img src={beive_logo} alt="" />
    </div>
  );
};

export default PaymentSuccess;
