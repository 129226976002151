import { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import jwt from "jsonwebtoken";
import sound from "../sound/mario_coin.mp3";

const SocketContext = createContext();
const token = jwt.decode(localStorage.getItem("authToken"));

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const song = {
    audio: new Audio(sound),
    isPlaying: false,
  };

  const isAuthorized = () => {
    const authorizeduser =
      (token && (token.role === "worker" || token.role === "manager")) === true;
    return authorizeduser;
  };

  const play = () => {
    let isPlaying = song.isPlaying;
    if (!isPlaying) {
      song.audio.play();
      song.audio.isPlaying = true;
    }
  };

  useEffect(() => {
    if (isAuthorized()) {
      const socket = io("/", {
        query: {
          stores: token.stores.map((store) => store.code),
          email: token.email,
        },
      });
      setSocket(socket);

      socket.on("newOrderEvent", () => {
        socket.emit("newOrderEventReceived");
        play();
      });
    }

    return () => socket && socket.close();
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
