import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToCart } from "../redux/actions/cartActions";
import { toast } from "react-toastify";
import Dialog from "./Dialog";
import "./Product.css";

const Product = ({ product, ingredients }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const { store } = useSelector((state) => state.selectStore);

  useEffect(() => {
    if (
      store === undefined ||
      store.code === undefined ||
      store.service === undefined ||
      store.service.type === undefined
    ) {
      history.push("/");
    }
  }, [dispatch, history, store]);

  const addProduct = (product) => {
    if (product.price > 0) {
      dispatch(addToCart(product));
      toast.success("Afegit!", { autoClose: 900 });
    }
  };
  const addToCartHandler = (product) => {
    var filteredSupplements = [];
    const ingredientNames = ingredients.map((ingredient) => ingredient.name);
    if (product.supplements.length > 0) {
      product.supplements.forEach((supplement) => {
        if (supplement.type !== "Cocció (obligatori)") {
          const newOptions = supplement.options.filter((option) =>
            ingredientNames.includes(option.name)
          );
          supplement.options = newOptions;
          filteredSupplements.push(supplement);
        }
      });

      setShowDialog(true);
    } else {
      addProduct(product);
    }
  };

  const onSubmitDialog = (option) => {
    if (option.choose) {
      const customProduct = Object.create(product);
      customProduct.supplements = option.selectedSupplements
        .map((supplement) => supplement.option)
        .sort((firstItem, secondItem) =>
          Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
        );
      customProduct.price = Number(
        Number(customProduct.price) +
          customProduct.supplements.reduce(
            (price, supplement) => Number(price) + Number(supplement.price),
            0
          )
      );

      addProduct(customProduct);
    }
    setShowDialog(false);
  };

  return (
    <div className="card">
      <div className="row align-items-center">
        <div className="card-body text-align-center">
          <h5 className="card-title">{product.name}</h5>
          <img src={product.imageUrl} alt="" className="image" />
          <p className="card-description">{product.description}</p>
        </div>
        <div className="card-button">
          <div className="d-grid gap-2">
            <h5 className="card-price">{product.price}€</h5>
            <Button variant="primary" onClick={() => addToCartHandler(product)}>
              +
            </Button>
          </div>
        </div>
      </div>
      {showDialog && (
        <Dialog product={product} onSubmitDialog={onSubmitDialog} />
      )}
    </div>
  );
};

export default Product;
