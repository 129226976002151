import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import {
  getStores as listStores,
  selectStore,
} from "../redux/actions/storeActions";
import { clearCart } from "../redux/actions/cartActions";

const Stores = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getStores = useSelector((state) => state.getStores);
  const { store } = useSelector((state) => state.selectStore);
  const { stores, loading, error } = getStores;

  useEffect(() => {
    dispatch(listStores());
  }, [dispatch, history]);

  const chooseStore = (newStore) => {
    if (
      store !== undefined &&
      newStore !== undefined &&
      (store.code !== newStore.code ||
        newStore.service === undefined ||
        (store.service !== undefined &&
          store.service.type !== newStore.service.type))
    ) {
      dispatch(clearCart());
    }

    dispatch(selectStore(newStore));
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">INDICA ON VOLS DEMANAR</h6>
        <ListGroup as="ul">
          {loading ? (
            <div className="spinner-border m-5" role="status">
              <span className="sr-only" />
            </div>
          ) : error ? (
            <div>{error}</div>
          ) : stores !== undefined && stores.length > 0 ? (
            stores
              .sort((firstItem, secondItem) =>
                firstItem.name > secondItem.name ? 1 : -1
              )
              .filter((store) => store.active === "1")
              .map((store) => {
                if (store.inRestaurant === "1" || store.takeAway === "1") {
                  return (
                    <ListGroup.Item as="li" key={store.code}>
                      <Link
                        to={"/services"}
                        style={{ textDecoration: "none" }}
                        onClick={() => chooseStore(store)}
                      >
                        <div>{store.name}</div>
                      </Link>
                    </ListGroup.Item>
                  );
                } else {
                  return (
                    <ListGroup.Item as="li" key={store.code}>
                      <div>
                        <div>{store.name}</div>
                        <div className="text-danger">No Disponible</div>
                      </div>
                    </ListGroup.Item>
                  );
                }
              })
          ) : (
            <div />
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default Stores;
