import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./OrderDetail.css";

const OrderDetail = (data) => {
  const order = data.location.order;
  const history = useHistory();

  const back = () => {
    history.goBack();
  };

  return (
    <div>
      <div id="order-detail" className="card">
        {order !== undefined ? (
          <div className="row align-items-center">
            <div className="card-body">
              <div className="mb-3">
                <div className="d-grid gap-2">
                  <Button size="lg" onClick={back}>
                    TORNAR ENRERE
                  </Button>
                </div>
              </div>
              <Form>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Número</Form.Label>
                    <Form.Label className="text-center">{order.id}</Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Taula</Form.Label>
                    <Form.Label className="text-center">
                      {order.code}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Estat</Form.Label>
                    <Form.Label className="text-center">
                      {order.status}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Dia</Form.Label>
                    <Form.Label className="text-center">
                      {getTime(order.datetime)}
                    </Form.Label>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Servei</Form.Label>
                    <Form.Label className="text-center">
                      {order.service}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col} className="col-4">
                    <Form.Label className="fw-bold">Hora reserva</Form.Label>
                    <Form.Label className="text-center">
                      {order.time !== undefined && order.time !== "ASAP"
                        ? order.time
                        : ""}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Telèfon</Form.Label>
                    <Form.Label className="text-center">
                      {order.phone}
                    </Form.Label>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Gestió</Form.Label>
                    <Form.Label className="text-center">
                      {order.cost}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Propina</Form.Label>
                    <Form.Label className="text-center">{order.tip}</Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Import</Form.Label>
                    <Form.Label className="text-center">
                      {order.totalAmount}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="fw-bold">Punts</Form.Label>
                    <Form.Label className="text-center">
                      {order.points}
                    </Form.Label>
                  </Form.Group>
                </Row>

                <Row>
                  <table className="table table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th className="text-center">Artícle</th>
                        <th className="text-center">Preu</th>
                        <th className="text-center">Quant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.lines.map((line) => (
                        <tr key={line.id}>
                          <td>
                            {line.itemTitle}
                            {line.itemCustomName !== undefined &&
                            line.itemCustomName !== "" ? (
                              ` "${line.itemCustomName}"`
                            ) : (
                              <></>
                            )}
                            {line.itemDescription !== undefined &&
                            line.itemDescription.replace(/ /g, "") !== "" ? (
                              `. ${line.itemDescription}`
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{Number(line.price).toFixed(2)}</td>
                          <td>{line.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Row>
              </Form>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

const getTime = (datetime) => {
  const newDate = new Date(datetime);
  const minutes = newDate.getMinutes();
  const hours = newDate.getHours();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear().toString().slice(-2);

  return `${date < 10 ? `0${date}` : `${date}`}/${
    month < 10 ? `0${month}` : `${month}`
  }/${year} ${hours < 10 ? `0${hours}` : `${hours}`}:${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }`;
};

export default OrderDetail;
