import "./LegalTerms.css";

const LegalTerms = () => {
  return (
    <div className="mb-3 legal-text">
      <p>
        BEIVE, S.C.P., amb domicili a Manlleu, avinguda Puigmal número 80, i amb
        C.I.F. número J-66839960, és la titular del portal accessible per la URL
        https://app.beiveonline.com. Amb telèfon de contacte 935 13 38 53.
      </p>
      <p>
        L'accés al portal de Beive i a la informació relativa a qualsevol dels
        productes i serveis continguts en aquest, confereix la condició d'usuari
        i comporta l'acceptació de les condicions previstes en aquest Avís
        Legal. Per això us recomanem que llegiu atentament el seu contingut si
        voleu accedir i fer ús de la informació i els serveis oferts des de el
        portal de Beive.
      </p>
      <p>
        La informació i les condicions contingudes en aquestes pàgines són les
        vigents des de la data de la seva darrera actualització. Beive es
        reserva el dret a modificar-les en qualsevol moment, a el cas del qual
        entraran en vigor des de la seva publicació i seran aplicables a tots
        els usuaris del portal des d'aquesta data. Els continguts del portal,
        especialment les referències informatives i publicitàries, llevat que
        s'indiqui expressament el contrari, no constitueixen oferta vinculant.
        Beive es reserva el dret a introduir modificacions o ometre parcialment
        o totalment els actuals continguts del portal de Beive quan ho consideri
        oportú, així com impedir o restringir el accés de forma temporal o
        permanent
      </p>
      <p>
        El portal de Beive, les pàgines que comprèn i la informació o elements
        continguts a les inclouen textos, documents, fotografies, dibuixos,
        representacions gràfiques, bases de dades, programes informàtics, així
        com logotips, marques, noms comercials, o altres signes distintius,
        protegits per drets de propietat intel·lectual o industrial, dels quals
        Beive o les empreses del Grup són titulars o legítimes llicenciatàries.
        Beive no atorga cap garantia sobre la licitud i legalitat de la
        informació o elements continguts a les pàgines del portal de Beive quan
        la titularitat dels mateixos no correspongui a Beive ni a les empreses
        del seu grup.
      </p>
      <p>
        Queda prohibida qualsevol modalitat d'explotació, incloent-hi tota mena
        de reproducció, distribució, cessió a tercers, comunicació pública i
        transformació, mitjançant qualsevol tipus de suport i mitjà, de les
        obres abans referides, creacions i signes distintius sense autorització
        prèvia i expressa dels seus respectius titulars. L´incompliment
        d´aquesta prohibició podrà constituir infracció sancionable per la
        legislació vigent. No obstant això, pel vostre compte i risc, l'usuari
        podrà descarregar o fer còpia d'aquests elements exclusivament per al
        seu ús personal, sempre que no infringeixi cap dels drets de propietat
        intel·lectual o industrial de Beive. Especialment, no podrà alterar-los,
        modificar-los o suprimir-los ni totalment ni parcialment. En cap cas,
        això significarà una autorització o llicència sobre els drets de
        propietat de Beive o de les empreses del Grup. Queda prohibit, excepte
        en els casos que expressament ho autoritzi Beive, establir enllaços,
        hipervincles o links, des de portals o llocs web de tercers a pàgines
        web de Beive diferents de la pàgina principal del vostre portal,
        accessible a l'adreça URL https://app.beiveonline.com, o la que us
        substituïu en el futur, així com presentar les pàgines web de Beive o la
        informació continguda en elles sota frames o marcs, signes distintius,
        marques o denominacions socials o comercials altra persona, empresa o
        entitat.
      </p>
      <p>
        Beive no garanteix l'accés continuat, ni la correcta visualització,
        descàrrega o utilitat dels elements i informació continguts a les
        pàgines del portal de Beive, que es poden veure impedits, dificultats o
        interromputs per factors o circumstàncies fora del seu control. Beive no
        és responsable de la informació i altres continguts integrats en espais
        o pàgines web de tercers accessibles des del portal de Beive mitjançant
        enllaços, hipervincles o links, ni de la informació i altres continguts
        integrats en espais o pàgines web de tercers des de els que s'accedeixi
        mitjançant enllaços, hipervincles o links al portal de Beive oa
        qualsevol de les seves pàgines web, ni de la informació i continguts de
        qualsevol pàgina web de tercers que es present sota l'aparença o signes
        distintius de Beive, excepte autorització expressa d'aquesta darrera.
        Beive no assumeix cap responsabilitat amb relació a la informació,
        continguts de tota mena, productes i serveis oferts o prestats a través
        del portal de Beive per terceres persones o entitats. Beive tampoc no
        assumeix cap responsabilitat per danys, perjudicis, pèrdues,
        reclamacions o despeses, produïdes per: (i) interferències,
        interrupcions, fallades, omissions, avaries telefòniques, retards,
        bloquejos o desconnexions en el funcionament del sistema electrònic,
        motivades per deficiències, sobrecàrregues i errors a les línies i
        xarxes de telecomunicacions, o per qualsevol altra causa aliena al
        control de Beive. (ii) intromissions il·legítimes mitjançant l'ús de
        programes malignes de qualsevol tipus i mitjançant de qualsevol mitjà de
        comunicació, com ara virus informàtics o qualssevol altres (iii) ús
        indegut o inadequat de les pàgines web de Beive (iv) errors de seguretat
        o navegació produïts per un mal funcionament del navegador o per l'ús de
        versions no actualitzades.
      </p>
      <p>
        El contingut del present Avís Legal es regeix per la llei comuna
        espanyola.
      </p>
      <p>
        Enviaments. No es realitzen enviaments. La recollida de les comandes és
        exclusivament a la barra de l'establiment Beive.
      </p>
      <p>
        Plaç d'entrega. L'hora de recollida de la comanda s'estima pot variar
        15-45 minuts respecte a l'hora sol·licitada depenent del volum de
        treball del local a cada moment.
      </p>
      <p>
        Cancelacions i devolucions. No s'accepten canvis ni devolucions de
        productes si no s'han sol·licitat en el mateix moment de la recollida.
        No se aceptan variaciones sobre el pedido realizado por internet una vez
        hecho el pago con tarjeta. No s'accepten variacions sobre la comanda
        realitzada per internet un cop fet el pagament amb targeta. Qualsevol
        variació online o en propi boja a la recollida serà una nova comanda,
        amb les corresponents noves condicions i per això s'abonarà en el moment
        de la nova sol·licitud. Qualsevol incidencia serà tractada de manera
        personalitzada.
      </p>
      <p>
        Seguretat protecció compradors. Totes les operacions de pagament es
        realitzen a través de Redsys amb tots els sistemes d'encriptació i
        seguretat de transferència de dades a través del protocol de seguretat
        SSL, per tant, el comprador gaudirà en tot moment de la protecció
        d'aquests canals.
      </p>
      <p>
        Privacidad i protecció de dades. Us informem que per a l'accés de
        determinats continguts o serveis serà necessari facilitar dades de
        caràcter personal. Els Usuaris garantiran la seva veracitat, exactitud,
        autenticitat de les dades, Beive preservarà la confidencialitat i
        seguretat de les seves dades personals i donarà a aquestes el tractament
        automatitzat que correspongui en funció de la seva naturalesa o
        finalitat, en els termes indicats a la Secció de Política de protecció
        de dades. És important, i atesa la recent normativa europea en Protecció
        de Dades REGLAMENT UE 2016/679 del PARLAMENT i del CONSELL EUROPEU,
        llegiu la Informació Bàsica. També heu de saber que el fet de deixar les
        vostres dades no atribueix, ni confirma, ni anul·la, ni legitima, ni
        perfecciona cap vincle contractual amb la nostra empresa. FINALITAT:
        Gestionar la reserva i venda a distància de serveis relacionats amb la
        restauració, contractació de comandes online i per recollir als
        establiments. LEGITIMACIÓ: Consentiment, relació contractual i interès
        legítim. BASE LEGAL: Reglament (UE) 2016/679 del Parlament Europeu i del
        Consell, de 27 d'abril de 2016, relatiu a la protecció de les persones
        físiques pel que fa al tractament de dades personals ia la lliure
        circulació d'aquestes dades no cedirà en cap cas les seves dades
        personals, Llei orgànica 3/2018, de 5 de desembre, de protecció de dades
        personals i garantia dels drets digitals, Llei 34/2002, d'11 de juliol,
        de serveis de la societat de la informació i comerç electrònic. Reial
        decret legislatiu 1/2007, de 16 de novembre, pel qual s'aprova el text
        refós de la Llei general per a la defensa dels consumidors i usuaris i
        altres lleis complementàries. DESTINATARIS: No cedim dades a tercers com
        ara operats que gestionen la pàgina webs i que manté la nostra aplicació
        informàtica que ens permet la gestió ordinària dels nostres serveis,
        imprescindibles per a la gestió de la nostra activitat, per imperatiu
        legal. DURADA: Les dades proporcionades es conservaran durant un període
        de 5 anys des de la darrera contractació; sinó no és que vostè abans, no
        hagi sol·licitat el bloqueig o esborrat dels mateixos; o bé, el que
        prescriu la legislació tributària. QUALITAT DE LES DADES: Totes les
        dades sol·licitades a través del lloc web són obligatòries, ja que són
        necessàries per a la prestació d'un servei òptim a l'usuari. En cas que
        no siguin facilitades totes les dades, el prestador no garanteix la
        prestació dels serveis sol·licitats. L'usuari serà l'únic responsable
        respecte a la veracitat i actualització de les dades aportades a través
        dels diferents formularis del lloc web. INFORMACIÓ ADDICIONAL: Vostè en
        tot moment podrà demanar informació addicional sobre les seves dades
        personals (“segona capa”), a la mateixa adreça que li facilitem per
        poder exercir els seus drets. MENORS D'EDAT: Els nostres serveis no van
        dirigits a persones menors o incapacitats. Entenem que tenen
        l'autorització i el consentiment dels seus pares, tutors o representants
        legals. Serà sota la seva responsabilitat, la seva utilització sigui per
        negligència o per culpa invigilant, que pogués ocasionar una
        responsabilitat extracontractual. DRETS: Vostè podrà exercir els drets
        ARCO d'accés, rectificació, cancel·lació i/o oposició, així com el dret
        limitació de la finalitat i portabilitat. Podeu adreçar-vos a la nostra
        adreça de correu: info@beive.cat; o bé, a la nostra adreça postal
        dirigint-se al responsable del Fitxer de la nostra empresa. Per això
        caldrà aportar les dades personals i documents que ho acreditin,
        juntament amb la seva petició. Per això, cal que vostè mateix, o
        mitjançant persona legalment autoritzada aporti els següents •
        Documents: • Fotocòpia DNI • Identificació i representació suficient. •
        Explicació i objecte de la reclamació. • Domicili per a enviament de
        comunicacions, o correu electrònic, per posar-nos en contacte amb vostè
        com més aviat millor. INFORMACIÓ COMPLEMENTÀRIA: Vostè té dret a obtenir
        confirmació sobre com la nostra empresa, estem tractant les dades
        personals. Per tant, teniu dret a accedir a les vostres dades personals,
        les dades inexactes o sol·licitar-ne la supressió quan les dades ja no
        siguin necessàries per als fins que van ser recollides en qualsevol
        moment, de forma senzilla i gratuïta.
      </p>
      <p>Cookies. El lloc web no utilitza cookies.</p>
    </div>
  );
};

export default LegalTerms;
