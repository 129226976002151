import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  addCustomBurgerToCart,
  removeFromCart,
} from "../redux/actions/cartActions";
import "./CartItem.css";

const CartItem = (cartItem) => {
  const item = cartItem.item;
  const dispatch = useDispatch();
  const [isOutOfTIme, setIsOutOfTime] = useState();
  const { store } = useSelector((state) => state.selectStore);

  const addToCartHandler = (item) => {
    if (item.name === "HAMBURGUESA AL GUST") {
      dispatch(addCustomBurgerToCart(item));
    } else {
      dispatch(addToCart(item));
    }
  };

  const removeFromCartHandler = (item) => {
    dispatch(removeFromCart(item));
  };

  const isTimeOut = () => {
    if (cartItem.timeoutCategories === undefined) {
      return false;
    }
    const find = cartItem.timeoutCategories.find(
      (category) => item.type === category.type
    );

    setIsOutOfTime(find !== undefined ? find : false);
    return find !== undefined ? find : false;
  };

  useEffect(() => {
    if (
      store !== undefined &&
      store.service !== undefined &&
      store.service.type === "restaurant"
    ) {
      isTimeOut();
    }
  }, [isOutOfTIme, isTimeOut]);

  return (
    <div className="card">
      <div className="row align-items-center">
        <div className="card-body">
          <h5 className="card-title">
            {item.qty}x {item.name}
            {item.name === "HAMBURGUESA AL GUST" && item.burgerName !== "" ? (
              ` "${item.burgerName}"`
            ) : (
              <></>
            )}
          </h5>
          <img src={item.imageUrl} alt="" className="image" />
          {item.supplements === undefined ? (
            <div className="card-description">item.description </div>
          ) : (
            <></>
          )}

          <div className="card-description">
            {item.name !== "HAMBURGUESA AL GUST" && item.description !== " "
              ? item.description + ". "
              : ""}
            {item.supplements !== undefined
              ? item.supplements
                  .sort((firstItem, secondItem) =>
                    Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
                  )
                  .map((supplement) => supplement.name)
                  .join(", ")
              : ""}
          </div>
        </div>
        <div className="card-button">
          <div className="d-grid gap-2">
            <h5 className="card-price">
              {(item.qty * Number(item.price)).toFixed(2)}€
            </h5>
            <Button variant="primary" onClick={() => addToCartHandler(item)}>
              +
            </Button>
            <Button
              variant="primary"
              onClick={() => removeFromCartHandler(item)}
            >
              -
            </Button>
          </div>
        </div>
      </div>
      {isOutOfTIme ? (
        <a
          className="text-center text-danger"
          style={{ textDecoration: "none" }}
        >
          (Fora horari)
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CartItem;
