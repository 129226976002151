import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import CartPage from "./pages/Cart";
import CheckoutPage from "./pages/Checkout";
import ServiceTypePage from "./pages/ServiceType";
import OrdersPage from "./pages/Orders";
import MyOrdersPage from "./pages/MyOrders";
import OrderDetailPage from "./pages/OrderDetail";
import RedsysPage from "./pages/Redsys";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import LegalTerms from "./pages/LegalTerms";
import GuestPage from "./pages/Guest";
import StockPage from "./pages/Stock";
import ProductPage from "./pages/Products";
import StoresPage from "./pages/Stores";
import StorePage from "./pages/Store";
import CustomBurgerPage from "./pages/CustomBurger";
import LoginPage from "./pages/login/Login";
import RegisterPage from "./pages/login/Register";
import ForgotPasswordPage from "./pages/login/ForgotPassword";
import ResetPasswordPage from "./pages/login/ResetPassword";
import ProfilePage from "./pages/Profile";
import TicketPage from "./pages/print/Ticket";
import MenuPage from "./pages/Menu.js";
import EatInRestaurantPage from "./pages/EatInRestaurant";
import TimetablePage from "./pages/Timetables";
import BackofficePage from "./pages/Backoffice";
import OrderSearchPage from "./pages/OrderSearch";
import MyUserPage from "./pages/MyUser";
import SalesPage from "./pages/Sales";
import { SocketContextProvider } from "./components/SocketContext.js";

import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IncomeSummaryPage from "./pages/Income";

function App() {
  return (
    <div>
      <SocketContextProvider>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/" exact component={StoresPage} />
            <Route exact path="/backoffice" component={BackofficePage} />
            <Route path="/cart" component={CartPage} />
            <Route exact path="/checkout" component={CheckoutPage} />
            <Route exact path="/customBurger" component={CustomBurgerPage} />
            <Route
              exact
              path="/forgotpassword"
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path="/eatInRestaurant"
              component={EatInRestaurantPage}
            />
            <Route exact path="/guest" component={GuestPage} />
            <Route exact path="/legal" component={LegalTerms} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/income" component={IncomeSummaryPage} />
            <Route exact path="/menu" component={MenuPage} />
            <Route exact path="/myOrders" component={MyOrdersPage} />
            <Route exact path="/myUser" component={MyUserPage} />
            <Route exact path="/orders" component={OrdersPage} />
            <Route exact path="/orders/search" component={OrderSearchPage} />
            <Route exact path="/orders/:id" component={OrderDetailPage} />
            <Route
              exact
              path="/passwordreset/:resetToken"
              component={ResetPasswordPage}
            />
            <Route exact path="/payment/success" component={PaymentSuccess} />
            <Route exact path="/payment/failure" component={PaymentFailure} />
            <Route exact path="/print" component={TicketPage} />
            <Route exact path="/products/search" component={ProductPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/redsys" component={RedsysPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/sales" component={SalesPage} />
            <Route exact path="/services" component={ServiceTypePage} />
            <Route exact path="/stock" component={StockPage} />
            <Route exact path="/stores" component={StoresPage} />
            <Route exact path="/stores/:id" component={StorePage} />
            <Route exact path="/timetables" component={TimetablePage} />
          </Switch>
        </Router>
        <ToastContainer autoclose={900} />
      </SocketContextProvider>
    </div>
  );
}

export default App;
