import axios from "axios";
import * as actionTypes from "../constants/cartConstants";

const qty = 1;

const addHiddenCost = (selectStore) => {
  if (
    selectStore !== undefined &&
    selectStore.store !== undefined &&
    selectStore.store.service !== undefined &&
    selectStore.store.service.type === "restaurant"
  ) {
    return Number(0.1);
  }
  return Number(0);
};

export const addCustomBurgerToCart = (data) => async (dispatch, getState) => {
  const { selectStore } = getState();
  const supplementIds = data.supplements
    .sort((firstItem, secondItem) =>
      Number(firstItem.order) > Number(secondItem.order) ? 1 : -1
    )
    .map((supplement) => supplement._id)
    .join("");

  dispatch({
    type: actionTypes.ADD_TO_CART,
    payload: {
      burgerName: data.burgerName,
      id: supplementIds,
      type: "CustomBurger",
      name: "HAMBURGUESA AL GUST",
      description: data.description,
      imageUrl: "",
      price: Number(data.price) + addHiddenCost(selectStore),
      supplements: data.supplements,
      qty,
    },
  });

  localStorage.setItem("cart", JSON.stringify(getState().cart.cartItems));
};

export const addToCart = (item) => async (dispatch, getState) => {
  const { selectStore } = getState();
  const id = item.id !== undefined ? item.id : item._id;

  var { data } = await axios.get(`/api/products/${id}`);
  var firstItem = data[0];

  const supplementAmounts = item.supplements.reduce(
    (price, supplement) => Number(price) + Number(supplement.price),
    0
  );

  dispatch({
    type: actionTypes.ADD_TO_CART,
    payload: {
      id: id,
      type: firstItem.type,
      name: firstItem.name,
      description: firstItem.description,
      imageUrl: firstItem.imageUrl,
      price:
        Number(firstItem.price) +
        Number(supplementAmounts) +
        addHiddenCost(selectStore),
      supplements: item.supplements,
      qty,
    },
  });

  localStorage.setItem("cart", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (item) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: item,
  });

  localStorage.setItem("cart", JSON.stringify(getState().cart.cartItems));
};

export const clearCart = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLEAR_CART,
  });

  localStorage.setItem("cart", JSON.stringify(getState().cart.cartItems));
};
