import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Product from "../components/Product";
import { getProducts as listProducts } from "../redux/actions/productActions";
import { getIngredients as listIngredients } from "../redux/actions/ingredientActions";

const Products = () => {
  const dispatch = useDispatch();
  const getProducts = useSelector((state) => state.getProducts);
  const { store } = useSelector((state) => state.selectStore);
  const { products, loading, error } = getProducts;
  const location = useLocation();
  const { type } = location.state;
  const { active } = location.state;
  const getIngredients = useSelector((state) => state.getIngredients);
  const { ingredients } = getIngredients;

  useEffect(() => {
    dispatch(
      listProducts({
        store: store !== undefined ? store.code : undefined,
        service:
          store !== undefined && store.service !== undefined
            ? store.service.type
            : undefined,
        type,
        active,
      })
    );

    const storeCode = store !== undefined ? store.code : undefined;
    dispatch(listIngredients({ active: true, store: storeCode }));
  }, [dispatch, active, type, store]);

  return (
    <div>
      <div>
        <h6 className="card-header">
          {products !== undefined ? products.length : 0} PRODUCTES
        </h6>
        {loading ? (
          <div className="spinner-border m-5" role="status">
            <span className="sr-only" />
          </div>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          products
            .sort((firstItem, secondItem) =>
              Number(firstItem.id) > Number(secondItem.id) ? 1 : -1
            )
            .map((product) => (
              <Product
                key={product._id}
                product={product}
                ingredients={ingredients}
              />
            ))
        )}
      </div>
      <div className="legal-terms">
        Copyright © 2023 Beive, SCP - <Link to="/legal">Avís legal</Link>
      </div>
    </div>
  );
};

export default Products;
